import useSaasChargeProdAxiosInterceotor from './useSaasChargeProdAxiosInterceotor';

const useSaasChargeProdApiHook = () => {
  const { instanceSassChargeProd, isSassLoading } =
    useSaasChargeProdAxiosInterceotor();

  const handleSassApiCall = async ({ method, url, data, headers }) =>
    await instanceSassChargeProd({
      method,
      url,
      data,
      headers,
    });

  return { handleSassApiCall, isSassLoading };
};

export default useSaasChargeProdApiHook;
