import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CardTimer from './CardTimer';
import {
  calenderSvg,
  lineSvg,
  tripCardClockSvg,
  tripLocatorPinSvg,
  tripStopSvg,
} from 'helper/helper';

const UpComingTripCard = ({ card, getMyTrips }) => {
  const navigate = useNavigate();

  function dateComing(targetDate) {
    const now = new Date();
    const diffTime = targetDate - now;

    if (diffTime > 24 * 60 * 60 * 1000) {
      // More than 24 hours
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      const years = Math.floor(diffDays / 365);
      const remainingDaysAfterYears = diffDays % 365;

      const months = Math.floor(remainingDaysAfterYears / 30);
      const remainingDays = remainingDaysAfterYears % 30;

      return `${years > 0 ? `${years}y, ` : ''}${
        months > 0 ? `${months}m, ` : ''
      }${remainingDays}d`;
    } else {
      // Within 24 hours
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.floor(
        (diffTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

      return `${diffHours} h, ${diffMinutes} m, ${diffSeconds} s`;
    }
  }

  const remainingTime = dateComing(
    card?.startDate && card?.startTime
      ? new Date(card?.startTime)
      : card?.startDate &&
        new Date(card?.startDate).getDate() === new Date().getDate() &&
        new Date(card?.startDate).getMonth() === new Date().getMonth() &&
        new Date(card?.startDate).getFullYear() === new Date().getFullYear()
      ? new Date(new Date().setHours(23, 59, 59))
      : new Date(card?.startDate)
  );

  const openTripDetail = () => {
    navigate(`/upcoming-trip/detail/${card?._id}`);
  };

  return (
    <div
      className={`ez__TripCard shadow-md md:max-w-[325px] transition-all duration-300 ease-in-out hover:cursor-pointer flex flex-col bg-white rounded-lg overflow-hidden p-4 justify-between ${
        card?.startDate || card?.startTime
          ? 'hover:bg-ezLightGreen'
          : 'hover:bg-ezLightGray'
      }`}
      onClick={() => openTripDetail()}
    >
      {!remainingTime?.includes('h') ? (
        <div className='max-w-fit rounded-md mb-5 md:mb-0 px-3 py-2 flex items-center justify-center text-xs bg-ezLightGreen border border-ezGreen text-ezGreen'>
          <div className='text-center flex flex-row w-full justify-center'>
            {card?.startDate ? remainingTime : 'Not Scheduled'}{' '}
            {card?.startDate && 'To Go'}
          </div>
        </div>
      ) : (
        <CardTimer
          getMyTrips={getMyTrips}
          startTime={
            card?.startDate && card?.startTime
              ? card?.startTime
              : card?.startDate
              ? new Date(new Date(new Date().setHours(23)).setMinutes(59))
              : null
          }
        />
      )}
      {/* <div className='ez__CardInfo ez__InfoText flex w-full flex-wrap items-center justify-between'> */}
      <div className='text-center flex flex-col justify-center items-center'>
        <div className='flex flex-row justify-between text-sm my-4 text-ezGray w-full'>
          <div className='text-left'>
            <p className='text-black  text-xs'>FROM</p>
            <p>{card?.origin?.text?.slice(0, 10)}...</p>
          </div>
          <img
            src='/assets/svgs/originToDest.svg'
            alt='originToDest'
            className='w-24 h-auto mx-3'
          />
          <div className='text-left'>
            <p className='text-black text-xs'>TO</p>
            <p>{card?.destination?.text?.slice(0, 10)}...</p>
          </div>
        </div>
        <span>{lineSvg}</span>
        <div className='flex flex-row flex-wrap gap-y-2 gap-x-3 mt-3 md:mt-0 pt-4 items-center'>
          <p className='text-ezGray text-sm mr-2 flex align-middle items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{calenderSvg}</span>{' '}
            {card?.startDate
              ? moment(card?.startDate).format('MMMM DD, YYYY')
              : 'Not Scheduled'}
          </p>
          <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{tripCardClockSvg}</span>{' '}
            {new Date(card?.startTime).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
          <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{tripStopSvg}</span>{' '}
            {card?.stops?.length}
          </p>
          <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{tripLocatorPinSvg}</span>{' '}
            {card?.distance}
          </p>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default UpComingTripCard;
