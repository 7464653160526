import Spiner from 'helper/Spiner';

const GrayBtn = ({
  isLoading = false,
  btnType = 'button',
  isDisabled = false,
  handleEvent,
  text,
  classNameCondition,
}) => {
  return (
    <button
      type={btnType}
      onClick={handleEvent}
      disabled={isDisabled}
      className={`
        ${isDisabled ? 'bg-ezLightGray border border-ezGray text-ezGray' : ''}
        ${
          classNameCondition
            ? 'bg-ezGreen hover:bg-transparent hover:text-ezGreen border-ezGreen cursor-pointer'
            : 'bg-ezLightGray hover:bg-ezLightGray border-ezLightGray cursor-not-allowed	hover:cursor-not-allowed'
        }  text-white rounded-md py-2 w-full text-center block border `}
    >
      {isLoading ? <Spiner color='white' width='w-full h-6' /> : text}
    </button>
  );
};

export default GrayBtn;
