import { useState } from 'react';
import { BiUndo } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';

export default function AdCard({ alignment, ads, className = '' }) {
  const [hideAd, setHideAd] = useState(false);

  const handleAdHide = () => {
    setHideAd(!hideAd);
  };

  return (
    <div className={`relative ${className}`}>
      <a
        href={`${ads?.link}`}
        target='_blank'
        rel='noreferrer'
        className='block w-full h-full relative'
      >
        <img
          src={
            alignment === 'vertical'
              ? ads?.images[1]?.src
              : alignment === 'horizontal'
              ? ads?.images[2]?.src
              : ads?.images[0]?.src
          }
          alt={ads?.title}
          className={`object-cover w-full h-full transition-opacity duration-300 ${
            hideAd ? 'opacity-0' : 'opacity-100'
          }`}
        />
        <div
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-300 ${
            hideAd ? 'opacity-100' : 'opacity-0'
          } bg-gray-300 bg-opacity-40`}
        >
          {hideAd && (
            <button
              className='absolute top-0 right-0 p-2 text-white'
              onClick={handleAdHide}
            >
              <BiUndo className='text-white' />
            </button>
          )}
        </div>
      </a>
      <button
        className='absolute top-1 right-1 p-0.5 text-white bg-ezLightGray rounded-full focus:outline-none'
        onClick={handleAdHide}
      >
        {hideAd ? <BiUndo /> : <FaTimes />}
      </button>
    </div>
  );
}
