const OutlineBtn = ({
  isApiLoading = false,
  isDisabled = false,
  btnType = 'button',
  text,
  handleEvent = () => {},
}) => {
  return (
    <button
      type={btnType}
      isDisabled={isDisabled || isApiLoading}
      onClick={handleEvent}
      className={`${
        isDisabled ? 'bg-ezLightGray border border-ezGray text-ezGray' : ''
      }
      border border-ezGreen bg-transparent text-ezGreen rounded-md py-2 w-full text-center block hover:bg-ezGreen hover:text-white`}
    >
      {text}
    </button>
  );
};

export default OutlineBtn;
