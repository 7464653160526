import React from 'react';

function RadioBtn({
  text,
  isSelected,
  isDisabled = false,
  onClick,
  classNames,
  multiple = false,
}) {
  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  function handleIsDisabled() {
    if (multiple) {
      return isDisabled;
    }
    return isDisabled || isSelected;
  }

  return (
    <button
      onClick={handleClick}
      disabled={handleIsDisabled()}
      className={`inline-block border transition-colors duration-300 min-w-fit
            ${classNames ?? 'px-4 py-2 my-2 rounded-full text-sm'}
            ${isSelected ? 'border-ezGreen bg-ezLightGreen text-ezGreen' : ''}
            ${
              !isSelected
                ? 'border-gray-300 bg-gray-200 text-black hover:bg-ezLightGray hover:text-black bg-opacity-70'
                : multiple
                ? 'border-ezGreen'
                : 'border-ezGreen cursor-default'
            }`}
    >
      {text.toUpperCase()}
    </button>
  );
}

export default RadioBtn;
