import PrimaryBtn from "components/btns/PrimaryBtn";
import Spiner from "helper/Spiner";
import { plus } from "helper/helper";
import useApiHook from "hooks/useApiHook";
import DeleteModal from "modals/common/DeleteModal";
import AddPaymentModal from "modals/payment/AddPaymentModal";
import { useEffect, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MyPayment = () => {
  const { auth } = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isModal, setIsModal] = useState(false);
  const [isDelModal, setIsDelModal] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserPaymentMethos = async () => {
    setIsLoading(true);
    const result = await handleApiCall({
      method: "get",
      url: `account/payment-methods/${auth?.userInfo?.user?._id}`,
    });
    if (result?.status === 200) setPaymentMethods(result?.data?.paymentMethods);
    setIsLoading(false);
  };

  const deletePaymentMethod = async () => {
    const result = await handleApiCall({
      method: "delete",
      url: `/account/customer/payment/${auth?.userInfo?.user?._id}/${paymentId}`,
    });
    setPaymentId(null);
    setIsDelModal(false);
    if (result?.status === 200) {
      toast.success("Your card has been deleted.");
      getUserPaymentMethos();
    }
  };

  useEffect(() => {
    if (location.search) {
      navigate("/my-payment-methods");
      toast.success("Your payment method has been added.");
    }
    getUserPaymentMethos();
  }, []);

  return (
    <div className="ez__MyTrip w-full bg-ezMidWhite px-4 py-10 md:p-10">
      <h3 className="text-ezBlack text-2xl mb-3 md:mb-1">Payment</h3>
      <h3 className="text-ezNuturalGray text-base mb-3 ">
        Here’s the list of your added payment methods.
      </h3>

      <div className="block md:w-1/2 lg:w-1/3 w-full">
        <div className="p-6 bg-white rounded-lg ">
          <h3 className="text-ezBlack text-base mb-3 ">ADD NEW CARD</h3>
          <div
            className=" ezCard-width shadow-sm flex p-5 gap-2 justify-center items-center border border-dashed border-ezGreen rounded-md hover:cursor-pointer hover:bg-ezLightGreen hover:text-ezGreen transition-all duration-300 ease-in-out"
            onClick={() => setIsModal(true)}
          >
            <BiPlusCircle className="w-5 h-5 text-ezGreen" />
            <p className=" text-ezGreen">Add New Card</p>
          </div>
        </div>
        {isApiLoading && isLoading ? (
          <div className="col-span-3 block w-full h-96">
            <Spiner color="ezGreen" />
          </div>
        ) : (
          <div className="grid grid-cols-1  gap-4 p-6 mt-2 bg-white rounded-lg ">
            <h3 className="text-ezBlack text-base  ">PREVIOUS CARDS</h3>

            {paymentMethods?.data?.length > 0 ? (
              paymentMethods?.data?.map((method) => (
                <div
                  key={method?.id}
                  className="card ezCard-width shadow-sm bg-ezMidWhite rounded-md flex items-center justify-between p-5"
                >
                  <div className="cardInfo flex items-center">
                    <img
                      src={`/assets/images/cards/${method?.card?.brand}.png`}
                      alt="Card Image"
                      className="w-12 h-auto"
                    />
                    <div className="cardContent ml-4">
                      <p className="text-ezNuturalGray text-sm">CARD NUMBER</p>
                      <span className="text-[#24282D] text-xs">
                        **** **** **** {method?.card?.last4}
                      </span>
                    </div>
                    <div className="cardContent ml-2">
                      <p className="text-ezNuturalGray text-sm">EXP</p>
                      <span className="text-[#24282D] text-xs">
                        {method?.card?.exp_month}/
                        {method?.card?.exp_year?.toString().slice(-2)}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setIsDelModal(true);
                      setPaymentId(method?.id);
                    }}
                    className="text-ezRed"
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              ))
            ) : (
              <div className="col-span-3 w-full flex items-center justify-center flex-col py-32">
                <img
                  src="/assets/svgs/noPayment.svg"
                  alt="No Trip"
                  className="w-32 md:w-56 h-auto mb-5"
                />
                <p className="text-ezGray text-sm md:text-base mb-5">
                  There is no payment method
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      {isModal && <AddPaymentModal isModal={isModal} setIsModal={setIsModal} />}
      {isDelModal && (
        <DeleteModal
          isModal={isDelModal}
          isLoading={isApiLoading}
          handleCloseModal={() => {
            setIsDelModal(false);
            setPaymentId(null);
          }}
          title="Payment Method"
          description="Are you sure you want to delete this payment method?"
          handleDelete={deletePaymentMethod}
        />
      )}
    </div>
  );
};

export default MyPayment;
