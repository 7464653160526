import AdCard from "components/Advertisment/AdCard";
import PrimaryBtn from "components/btns/PrimaryBtn";
import TripCard from "components/myTrips/TripCard";
import UpComingTripCard from "components/myTrips/UpComingTripCard";
import Spiner from "helper/Spiner";
import {
  filterAndSortPreviousTrips,
  filterAndSortUpcomingTrips,
} from "helper/functionality";
import useApiHook from "hooks/useApiHook";
import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  handlPlanTripModal,
  toggleIsPlanApiCall,
  toggleTripsPage,
} from "redux/dashbbaord/dashboardSlice";

const MyTrip = () => {
  const { auth, dashboard } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [trips, setTrips] = useState("upcoming");
  const [previousTrips, setPreviousTrips] = useState([]);
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpcomingLoading, setIsUpcomingLoading] = useState(false);
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [previousPage, setPreviousPage] = useState(1);
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [previousCount, setPreviousCount] = useState(0);

  const getMyTripsAndSortUpcoming = async () => {
    const result = await handleApiCall({
      method: "get",
      url: `/trip/upcoming/${auth?.userInfo?.user?._id}?page=${upcomingPage}&limit=10`,
    });
    if (result?.status === 200) {
      setUpcomingCount(result?.data?.count);
      const newUpcomingTrips = filterAndSortUpcomingTrips([
        ...result?.data?.trips,
      ]) || [];
      setUpcomingTrips((prevTrips) => {
        const uniqueTrips = [...new Set([...prevTrips, ...newUpcomingTrips])];
        return uniqueTrips;
      });
      dispatch(toggleIsPlanApiCall(false));
    }
    if (!isApiLoading || isUpcomingLoading) {
      setIsLoading(false);
      setIsUpcomingLoading(false);
    }
  };

  const getMyTripsAndSortPrevious = async () => {
    const result = await handleApiCall({
      method: "get",
      url: `/trip/previous/${auth?.userInfo?.user?._id}?page=${previousPage}&limit=10`,
    });
    if (result?.status === 200) {
      setPreviousCount(result?.data?.count);
      const newPreviousTrips = filterAndSortPreviousTrips([
        ...result?.data?.trips,
      ]) || [];
      setPreviousTrips((prevTrips) => {
        const uniqueTrips = [...new Set([...prevTrips, ...newPreviousTrips])];
        return uniqueTrips;
      });
      dispatch(toggleIsPlanApiCall(false));
    }
    if (!isApiLoading || isPreviousLoading) {
      setIsLoading(false);
      setIsPreviousLoading(false);
    }
  };

  const handlePreviousPageChange = () => {
    setPreviousPage(previousPage + 1);
    getMyTripsAndSortPrevious();
  };

  const handleUpcomingPageChange = () => {
    setUpcomingPage(upcomingPage + 1);
    getMyTripsAndSortUpcoming();
  };

  useEffect(() => {
    if (!dashboard?.isPlanApiCall && auth?.userInfo?.user?._id) {
      setIsLoading(true);
      getMyTripsAndSortUpcoming();
      getMyTripsAndSortPrevious();
    }
    dispatch(toggleTripsPage(true));
  }, []);

  useEffect(() => {
    if (dashboard?.isPlanApiCall && auth?.userInfo?.user?._id) {
      getMyTripsAndSortUpcoming();
      getMyTripsAndSortPrevious();
    }
  }, [dashboard?.isPlanApiCall]);

  return (
    <div className="ez__MyTrip w-full bg-ezMidWhite px-4 py-10 md:p-10">
      <div className="ez__Title w-full flex md:items-center justify-between mb-8 gap-y-6 md:gap-y-0 flex-col md:flex-row">
        <h3 className="text-ezBlack font-semibold text-3xl mb-auto h-full">
          My Trips
        </h3>
        <div className="mx-auto md:mx-0 md:ml-0 ez__Tabs w-max bg-white p-2 border-ezGreen rounded-lg overflow-hidden">
          <button
            onClick={() => setTrips("previous")}
            className={`rounded-lg ${
              trips === "previous"
                ? "bg-ezGreen text-white"
                : "text-ezGreen bg-transparent"
            } text-sm md:text-base py-3 px-2 md:px-3`}
          >
            <span className="px-4">Past Trips</span>
          </button>
          <button
            onClick={() => setTrips("upcoming")}
            className={`rounded-lg ${
              trips === "upcoming"
                ? "bg-ezGreen text-white"
                : "text-ezGreen bg-transparent"
            } text-sm md:text-base py-3 px-4 md:px-6`}
          >
            Upcoming Trips
          </button>
        </div>
      </div>
      {!isLoading && trips === "upcoming" && dashboard?.ads?.length > 0 && (
        <div className="hidden md:flex mb-6 md:p-3 flex-col md:flex-row items-center justify-center overflow-hidden">
          <AdCard
            key={"TripLargeAdKeyHorizontal"}
            alignment={"horizontal"}
            ads={dashboard?.ads[0]}
            className="hidden md:block lg:w-2/3 md:w-4/5 w-2/3"
          />
        </div>
      )}
      <div
        className={
          trips === "previous"
            ? "grid grid-cols-1 md:gap-x-2 md:flex md:flex-row "
            : "block"
        }
      >
        <div className="flex flex-row flex-wrap gap-4 w-full content-start">
          {isLoading && (
            <div className="col-span-3 block w-full h-96">
              <Spiner color="ezGreen" />
            </div>
          )}

          {!isLoading && trips === "previous" && previousTrips?.length > 0
            ? previousTrips?.map((card, index) => (
                <>
                  {!isLoading &&
                    index % 4 === 0 &&
                    index !== 0 &&
                    dashboard?.ads?.length > 0 && (
                      <div className="flex justify-center md:hidden">
                        <AdCard
                          key={`TripAdKey${card?._id}`}
                          alignment={"mobile"}
                          ads={dashboard?.ads[0]}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )}
                  <div className="col-span-10">
                    <TripCard key={`TripCardKey${card?._id}`} card={card} />
                  </div>
                </>
              ))
            : trips === "previous" &&
              !isLoading && (
                <>
                  {!isLoading && dashboard?.ads?.length > 0 && (
                    <div className="flex mb-6 md:p-3 flex-col md:flex-row items-center justify-center overflow-hidden">
                      <AdCard
                        key={"TripLargeAdKeyIfNoTrips"}
                        alignment={"horizontal"}
                        ads={dashboard?.ads[0]}
                        className="hidden md:block lg:w-2/3 md:w-4/5 w-2/3"
                      />
                    </div>
                  )}
                  <div className="w-full flex items-center justify-center flex-col py-32">
                    {isImageLoaded ? null : (
                      <Skeleton
                        variant="rectangular"
                        className="mb-5"
                        width={100}
                        height={100}
                      />
                    )}
                    <img
                      src="/assets/svgs/noTrip.svg"
                      alt="No Trip"
                      className="w-32 md:w-56 h-auto mb-5"
                      onLoad={() => setIsImageLoaded(true)}
                    />
                    <p className="text-ezGray text-sm md:text-base mb-5">
                      There are no previous trips
                    </p>
                    <PrimaryBtn
                      handleEvent={() => dispatch(handlPlanTripModal(true))}
                      text="Plan a trip"
                      btnType="button"
                      classNames="w-24 md:w-80"
                    />
                  </div>
                </>
              )}
          {!isLoading && trips === "upcoming" && upcomingTrips?.length > 0
            ? upcomingTrips?.map((card, index) => (
                <>
                  {!isLoading &&
                    index % 4 === 0 &&
                    index !== 0 &&
                    dashboard?.ads?.length > 0 && (
                      <div className="flex md:hidden flex-col items-center justify-center overflow-hidden">
                        <AdCard
                          key={`TripSmallAdKey${card?._id}`}
                          alignment={"mobile"}
                          ads={dashboard?.ads[0]}
                          className="md:hidden w-full h-full object-cover"
                        />
                      </div>
                    )}
                  {!isLoading &&
                    upcomingTrips?.length < 4 &&
                    index === upcomingTrips?.length - 1 &&
                    dashboard?.ads?.length > 0 && (
                      <div className="flex md:hidden flex-col md:flex-row items-center justify-center overflow-hidden">
                        <AdCard
                          alignment={"mobile"}
                          ads={dashboard?.ads[0]}
                          className="md:hidden w-full h-full object-contain"
                        />
                      </div>
                    )}
                  <UpComingTripCard
                    key={`UpComingTripCardKey${card?._id}`}
                    card={card}
                    getMyTrips={getMyTripsAndSortUpcoming}
                  />
                </>
              ))
            : trips === "upcoming" &&
              !isLoading && (
                <>
                  {!isLoading && dashboard?.ads?.length > 0 && (
                    <div className="flex mb-6 md:p-3 flex-col items-center justify-center overflow-hidden">
                      {/* <AdCard
                        alignment={"horizontal"}
                        ads={dashboard?.ads[0]}
                        className="hidden md:block lg:w-2/3 md:w-4/5 w-2/3"
                      /> */}
                      <AdCard
                        alignment={"mobile"}
                        ads={dashboard?.ads[0]}
                        className="md:hidden w-full h-full object-cover"
                      />
                    </div>
                  )}
                  <div className="w-full flex items-center justify-center flex-col py-32">
                    {isImageLoaded ? null : (
                      <Skeleton
                        variant="rectangular"
                        className="mb-5"
                        width={100}
                        height={100}
                      />
                    )}
                    <img
                      src="/assets/svgs/noTrip.svg"
                      alt="No Trip"
                      className="w-32 md:w-56 h-auto mb-5"
                      onLoad={() => setIsImageLoaded(true)}
                    />
                    <p className="text-ezGray text-sm md:text-base mb-5">
                      You have no upcoming trips
                    </p>
                    <PrimaryBtn
                      handleEvent={() => dispatch(handlPlanTripModal(true))}
                      text="Plan a trip"
                      btnType="button"
                      classNames="w-24 md:w-80"
                    />
                  </div>
                </>
              )}
        </div>
        {!isLoading &&
          trips === "previous" &&
          dashboard?.ads?.length > 0 &&
          previousTrips?.length !== 0 && (
            <div className="hidden md:flex md:flex-col md:justify-start">
              <AdCard
                alignment={"vertical"}
                ads={dashboard?.ads[0]}
                className="lg:w-40 md:w-36 w-24"
              />
            </div>
          )}
      </div>
      <div className="w-full flex items-center justify-center mb-5 mt-10">
        {isApiLoading
          ? null
          : trips === "previous" &&
            previousTrips?.length < previousCount && (
              <button
                className={`rounded-lg bg-ezGreen text-white text-sm md:text-base py-3 px-4 md:px-6`}
                onClick={() => {
                  setIsPreviousLoading(true);
                  handlePreviousPageChange();
                }}
              >
                { isPreviousLoading ? '' : 'Load More'}
              </button>
            )
          }
        {isApiLoading
          ? null
          : trips === "upcoming" &&
            upcomingTrips?.length < upcomingCount && (
              <button
                className={`rounded-lg bg-ezGreen text-white text-sm md:text-base py-3 px-4 md:px-6`}
                onClick={() => {
                  setIsUpcomingLoading(true);
                  handleUpcomingPageChange();
                }}
              >
                { isUpcomingLoading ? '' : 'Load More'}
              </button>
            )}
      </div>
    </div>
  );
};

export default MyTrip;
