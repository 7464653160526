import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { mapInfo } from "helper/helper";

export default function InfoCard() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div
        className={`w-8 h-8 md:w-16 md:h-12 z-10 flex justify-center items-center md:rounded-none rounded-full ${
          isOpen
            ? "bg-ezGreen hover:cursor-default"
            : "bg-white hover:bg-ezLightGreen"
        }`}
      >
        <Menu.Button
          className="w-8 h-8 md:w-12 md:h-12 right-4 md:right-14 z-10 text-white flex justify-center items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          {/* <FaInfo className='w-4 md:w-6 h-4 md:h-6' /> */}
          {mapInfo(isOpen ? "white" : "black")}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        beforeLeave={() => setIsOpen(false)}
      >
        <Menu.Items className="absolute md:right-0 right-9 top-11 md:-top-44  md:bottom-14 z-10 w-32 md:w-64 md:origin-bottom-right origin-top-right rounded-lg bg-white shadow-lg  focus:outline-none">
          <div className="block w-full">
            <div className="ez__Body grid grid-cols-1 md:grid-cols-2 w-full md:gap-2 pb-5 p-2 md:p-2 rounded-b-md">
              <div className="block md:flex md:flex-col md:gap-2 w-full">
                <p className="flex items-center justify-center md:justify-start text-black text-xs md:text-sm">
                  <img
                    src="/assets/images/levelOne.png"
                    alt=""
                    className="w-auto h-8 md:h-10 mr-1"
                  />{" "}
                  Level 1
                </p>
                <p className="flex items-center justify-center md:justify-start text-black text-xs md:text-sm">
                  <img
                    src="/assets/images/ev.png"
                    alt=""
                    className="w-auto h-8 md:h-10 mr-1"
                  />{" "}
                  Level 2
                </p>
                <p className="flex items-center justify-center md:justify-start text-black text-xs md:text-sm">
                  <img
                    src="/assets/images/evFast.png"
                    alt=""
                    className="w-auto h-8 md:h-10 mr-1"
                  />{" "}
                  DC Fast
                </p>
              </div>
              <div className="block md:flex md:flex-col md:gap-2 w-full">
                <p className="flex items-center justify-center md:justify-start text-black text-xs md:text-sm pl-2 md:pl-0">
                  <img
                    src="/assets/images/evPrivate.png"
                    alt=""
                    className="w-auto h-6 md:h-10 mr-3"
                  />{" "}
                  Private
                </p>
                <p className="flex items-center justify-center md:justify-start my-2 text-black text-xs md:text-sm pl-2 md:pl-0">
                  <img
                    src="/assets/svgs/traffic.svg"
                    alt=""
                    className="w-auto h-4 md:h-6 mr-3"
                  />{" "}
                  Traffic
                </p>
                <p className="flex items-center justify-center md:justify-start my-2 text-black text-xs md:text-sm pl-2 md:pl-0">
                  <img
                    src="/assets/svgs/slow.svg"
                    alt=""
                    className="w-auto h-4 md:h-6 mr-3"
                  />{" "}
                  Slow
                </p>
              </div>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
