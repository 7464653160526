const Spiner = ({ color, width = 'w-full h-full' }) => {
  return (
    <div className={`flex items-center justify-center spinner ${width}`}>
      <div className={`dot1 bg-${color} rounded-full m-2`}></div>
      <div className={`dot2 bg-${color} rounded-full m-2`}></div>
      <div className={`dot3 bg-${color} rounded-full m-2`}></div>
    </div>
  );
};

export default Spiner;
