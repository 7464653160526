import moment from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import { GiElectric } from "react-icons/gi";

const ChargingActivityCard = ({ card }) => {
  return (
    <div
      className={`bg-white px-4 py-3 rounded-lg shadow-md flex flex-col justify-between transition-all duration-300 ease-in-out border overflow-visible h-full md:h-40 lg:h-40 w-full lg:min-w-fit ${
        card?.status === "charging" ? "border-ezOrange" : "border-ezLightGray"
      }`}
    >
      <div className="ez__CardChargeInfo flex w-full flex-wrap flex-col md:flex-row md:items-center justify-between relative">
        <div className="block mt-1">
          <div className="ez__InfoText flex flex-nowrap flex-col md:flex-row md:items-center md:justify-between mb-1">
            <h4 className="text-black text-base font-semibold mb-2 mt-4 md:mt-0">
              {card?.vehicleId?.make?.make} -
              <span className="ml-1">
                {card?.vehicleId?.make?.models[0]?.model} 
              </span>
            </h4>
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="flex items-center text-ezBlack text-sm md:mb-2">
              <div className="rounded-md border bg-gray-100 mr-2 p-2">
                <AiOutlineClockCircle className="w-4 h-4" />
              </div>
              <div>
                <p className="flex items-center">Time</p>
                <p className="text-ezBlack font-semibold mr-10 flex items-center mb-1 md:mb-0">
                  {moment(card?.startTime).format("HH:mm")} -
                  <span className="ml-1">
                    {card?.endTime
                      ? moment(card?.endTime).format("HH:mm")
                      : "charging"}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center text-ezBlack text-sm ">
              <div className="rounded-md border bg-gray-100 mr-2 p-2">
                <GiElectric className="w-4 h-4" />
              </div>
              <div>
                <p className="flex">Charge Box</p>
                <p className="text-ezBlack font-semibold mr-10 flex items-center">
                  {card?.chargeBoxId}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 -right-1">
          {card?.status === "charging" ? (
              <span className="py-1.5 px-5 block text-sm mb-1 text-white bg-ezOrange w-max rounded-md">
                Charging
              </span>
            ) : (
              <span className="py-1.5 px-5 block text-sm mb-1 text-ezGreen bg-white w-max rounded-md border border-ezGreen">
                Charged
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

export default ChargingActivityCard;
