import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BiCurrentLocation, BiTime } from 'react-icons/bi';
import { BsArrowDownUp } from 'react-icons/bs';
import { Autocomplete } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import StopInput from './StopInput';
import { Fragment } from 'react';
import { toast } from 'react-toastify';
import {
  allStopsEmpty,
  allStopsEqual,
  secondsToDHMS,
} from 'helper/functionality';
import 'react-datepicker/dist/react-datepicker.css';
import GrayBtn from 'components/btns/GrayBtn';
import useApiHook from 'hooks/useApiHook';
import { useDispatch } from 'react-redux';
import { colourStyles } from 'helper/helper';
import { evConnectorTypes, evNetworkTypes } from 'helper/nrelData';
import Select from 'react-select';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { handleUserVehicles } from 'redux/auth/authSlice';
import AddVehicle from 'modals/vehicles/AddVehicle';
import Spiner from 'helper/Spiner';

import PlanVehicleCard from './PlanVehicleCard';
import PlanVehicleList from './PlanVehicleList';
import UpdateVehicle from 'modals/vehicles/UpdateVehicle';
import RadioBtn from 'components/btns/RadioBtn';

const PlanTripSteps1And2 = ({
  formValues,
  setFormValues,
  setSteps,
  setDirections,
  setTripDirections,
  userInfo,
  directions,
}) => {
  const originRef = useRef();
  const destinationRef = useRef();
  const dispatch = useDispatch();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isLoading, setIsLoading] = useState(false);
  const [userVehicle, setUserVehicle] = useState([]);
  const [vehicle, setVehicle] = useState(formValues?.vehicle || null);
  const [isVehicleChange, setIsVehicleChange] = useState(false);
  const [isVehicleModal, setIsVehicleModal] = useState(false);
  const [isVehicleUpdate, setIsVehicleUpdate] = useState(false);
  const [currentTabs, setCurrentTabs] = useState(['trip', 'vehicle']);

  const currentDate = new Date().toLocaleDateString();

  const handleStop = () => {
    let date = new Date();
    let data = {
      id: date.toISOString(),
      value: '',
      type: 'stop',
    };
    setFormValues({
      ...formValues,
      inBetween: [...formValues?.inBetween, data],
    });
  };

  const handleBetween = (betweenId, value) => {
    let data = formValues?.inBetween?.map((between) => {
      if (between?.id === betweenId) {
        return {
          ...between,
          value,
        };
      }
      return between;
    });
    setFormValues({ ...formValues, inBetween: data });
  };

  const getDirections = async ({ origin, destination }) => {
    if (origin === destination)
      return toast.error('Trip origin and destination can not be same');
    if (
      new Date(formValues?.startDate).getMonth() < new Date().getMonth() &&
      new Date(formValues?.startDate).getDate() < new Date().getDate()
    )
      return toast.error('Trip start date must be the future date');
    if (
      formValues?.startTime &&
      formValues?.startTime <= new Date() &&
      new Date(formValues?.startDate).getMonth() === new Date().getMonth() &&
      new Date(formValues?.startDate).getDate() === new Date().getDate()
    )
      return toast.error('Trip time must be the future time');
    if (
      formValues.inBetween?.length > 1 &&
      allStopsEqual(formValues.inBetween) &&
      !allStopsEmpty(formValues.inBetween)
    )
      return toast.error('All stops can not be same');
    setIsLoading(true);
    try {
      const stops = [];

      formValues?.inBetween?.map((stop, index, array) => {
        if (stop?.value) {
          if (index === 0 && stop?.value === formValues?.startFrom) return null;
          else if (
            index === array.length - 1 &&
            stop?.value === formValues?.destination
          )
            return null;
          else if (array[index - 1]?.value === stop?.value && index !== 0)
            return null;
          else stops.push(stop);
        }
      });

      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin,
        destination,
        waypoints: stops?.map((stop) => ({
          location: stop?.value,
          stopover: true,
        })),

        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        avoidHighways: formValues?.avoidHighways || false,
        avoidTolls: formValues?.avoidTolls || false,
        durationInTraffic: formValues?.avoidTraffic || false,
      });

      let duration = 0;
      let distance = 0;
      let costMin = formValues?.network === 'Tesla' ? 0.4 : 0.2;
      let costMax = formValues?.network === 'Tesla' ? 0.5 : 0.3;

      directions?.routes[0]?.legs?.map((leg) => {
        distance += leg?.distance?.value;
      });
      results?.routes[0]?.legs?.map((leg) => {
        duration += leg?.duration?.value;
      });
      results?.routes[0]?.legs?.map((leg) => {
        distance += leg?.distance?.value;
      });

      setFormValues({
        ...formValues,
        distance: `${Math.round(distance * 0.000621371192)} miles`,
        time: secondsToDHMS(duration),
        inBetween: stops,
        speed: `${Math.round(
          (distance * 0.000621371192) / (duration / 3600)
        )} mph`,
        energy: `${Math.round(
          (formValues?.vehicle?.model?.batteryCapacity /
            formValues?.vehicle?.range) *
            (distance / 1000)
        )} kWh`,
        cost: `$${Math.round(
          (formValues?.vehicle?.model?.batteryCapacity /
            formValues?.vehicle?.range) *
            (distance / 1000) *
            costMin
        )} to $${Math.round(
          (formValues?.vehicle?.model?.batteryCapacity /
            formValues?.vehicle?.range) *
            (distance / 1000) *
            costMax
        )} EST`,
      });

      setDirections(results);
      setTripDirections(results);
      setIsLoading(false);
      setSteps(2);
    } catch (error) {
      setIsLoading(false);
      toast.error(
        'No route found against these points. Please enter the valid points'
      );
    }
  };

  const getUserCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        if (!position.coords.latitude || !position.coords.longitude) {
          return toast.error(
            'Geolocation is not enabled. Please enable your location to continue'
          );
        }
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            setFormValues({
              ...formValues,
              startFrom: response.results[0].formatted_address,
            });
            originRef.current.value = response.results[0].formatted_address;
          },
          (error) => {
            console.error(error);
          }
        );
        Geocode.geocode({
          location: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
          .then((response) => {
            console.log({ response });
          })
          .catch((e) => window.alert('Geocoder failed due to: ' + e));
      },
      (error) =>
        toast.error(
          'Geolocation is not enabled. Please enable your location to continue'
        )
    );
  };

  const getUserVehicles = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `/vehicle/${userInfo?.user?._id}`,
    });
    if (result.status === 200) {
      dispatch(handleUserVehicles(result?.data));
      setUserVehicle(result?.data);
      setVehicle(vehicle || result?.data[0]);
      setFormValues({
        ...formValues,
        vehicle: vehicle || result?.data[0],
      });
    }
  };

  const addUserVehicles = async (vehicle) => {
    dispatch(handleUserVehicles([...userVehicle, vehicle]));
    setUserVehicle([...userVehicle, vehicle]);
    setVehicle(vehicle);
    setFormValues({
      ...formValues,
      vehicle,
    });
  };

  const updateUserVehicles = async (vehicle) => {
    let updatedVehicles =
      userVehicle?.map((item) =>
        item?._id === vehicle?._id ? vehicle : item
      ) || [];
    dispatch(handleUserVehicles(updatedVehicles));
    setUserVehicle(updatedVehicles);
    setVehicle(vehicle);
    setFormValues({
      ...formValues,
      vehicle,
    });
  };

  const valid = () => {
    if (
      formValues?.startFrom &&
      formValues?.destination &&
      formValues?.connectorType &&
      formValues?.network &&
      formValues?.chargersType
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (userInfo) getUserVehicles(userInfo?.user?._id);
  }, []);

  useEffect(() => {
    if (formValues?.startFrom) originRef.current.value = formValues?.startFrom;
    if (formValues?.destination)
      destinationRef.current.value = formValues?.destination;
    if (window) {
      if (window.innerWidth < 768) setCurrentTabs(['trip']);
      window.addEventListener('resize', (val) => {
        if (val.currentTarget.innerWidth < 768) {
          setCurrentTabs(['trip']);
        } else {
          setCurrentTabs(['trip', 'vehicle']);
        }
      });
    }
  }, []);

  return (
    <>
      <div className='bg-white w-full pb-2 rounded-lg'>
        <div className='m-auto md:ml-0 ez__Tabs w-full bg-gray-100 p-2 border-ezGreen rounded-lg md:hidden block mb-2 overflow-hidden'>
          <button
            onClick={() => setCurrentTabs(['trip'])}
            className={`rounded-lg ${
              currentTabs?.includes('trip')
                ? 'bg-ezGreen text-white'
                : 'text-gray-600 bg-transparent'
            } sm:text-sm text-xs md:text-base w-1/2 py-3 px-2 md:px-6`}
          >
            Choose Trip Points
          </button>
          <button
            onClick={() => setCurrentTabs(['vehicle'])}
            className={`rounded-lg ${
              currentTabs?.includes('vehicle')
                ? 'bg-ezGreen text-white'
                : 'text-gray-600 bg-transparent'
            } sm:text-sm text-xs md:text-base w-1/2 py-3 px-2 md:px-3`}
          >
            <span className='px-4'>Your Vehicle</span>
          </button>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-10 gap-6'>
          {currentTabs?.includes('trip') && (
            <div className='ez__StepForm scroll-shadows w-full col-span-6 max-h-[32rem]  overflow-y-auto pr-2 relative'>
              <h2 className='p-0 m-0 font-bold mb-3  hidden md:block'>
                Choose Trip Points
              </h2>
              <h3 className='p-0 m-0 text-ezGray text-sm'>FROM</h3>
              <Autocomplete
                onPlaceChanged={() => {
                  const startingValue = originRef?.current?.value;
                  setFormValues({
                    ...formValues,
                    startFrom: startingValue || '',
                  });
                }}
              >
                <div className='ez__FormInput w-full flex items-center justify-between border border-gray-400 rounded-md px-3 py-2 mb-1'>
                  <input
                    type='text'
                    id='startingPoint'
                    placeholder='Enter Starting Point'
                    className='text-base text-ezGray'
                    ref={originRef}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setFormValues({
                          ...formValues,
                          startFrom: '',
                        });
                      }
                    }}
                  />
                  <button
                    onClick={getUserCurrentLocation}
                    htmlFor='startingPoint'
                    className='text-ezGray text-2xl cursor-pointer'
                  >
                    <BiCurrentLocation />
                  </button>
                </div>
              </Autocomplete>
              <button
                onClick={handleStop}
                className='ez__AddStop block w-full text-right text-sm -mt-1 text-ezGreen mb-2'
                type='button'
              >
                + Add Stop
              </button>
              {formValues?.inBetween?.length > 0 &&
                formValues?.inBetween?.map((between, index) => (
                  <Fragment key={between?.id}>
                    <StopInput
                      index={index}
                      between={between}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      handleBetween={handleBetween}
                    />
                  </Fragment>
                ))}
              <div className='flex justify-center mt-1'>
                <button
                  onClick={() => {
                    setFormValues({
                      ...formValues,
                      startFrom: formValues?.destination,
                      destination: formValues?.startFrom,
                    });
                    originRef.current.value = formValues?.destination;
                    destinationRef.current.value = formValues?.startFrom;
                  }}
                  className='text-ezGray bg-transparent border-0'
                >
                  <BsArrowDownUp className='w-5 h-5' />
                </button>
              </div>
              <h3 className='p-0 m-0 text-ezGray text-sm py-1'>TO</h3>
              <Autocomplete
                onPlaceChanged={() => {
                  const destinationValue = destinationRef?.current?.value;
                  setFormValues({
                    ...formValues,
                    destination: destinationValue || '',
                  });
                }}
              >
                <div className='mb-4 ez__FormInput w-full flex items-center justify-between border border-gray-400 rounded-md px-3 py-2'>
                  <input
                    type='text'
                    id='startingPoint'
                    placeholder='Enter Ending Point'
                    className='text-base text-ezGray'
                    ref={destinationRef}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setFormValues({
                          ...formValues,
                          destination: '',
                        });
                      }
                    }}
                  />
                  <label
                    htmlFor='startingPoint'
                    className='text-ezGray text-2xl cursor-pointer'
                  >
                    <AiOutlineSearch />
                  </label>
                </div>
              </Autocomplete>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                <div>
                  <h3 className='p-0 m-0 text-ezGray text-sm'>ENTER DATE</h3>
                  <div className='ez__FormInput w-full flex items-center justify-between border border-gray-400 rounded-md px-3 py-2 mb-4'>
                    <DatePicker
                      selected={formValues?.startDate}
                      id='startDate'
                      placeholderText={`${currentDate}`}
                      minDate={new Date()}
                      autoComplete='off'
                      onChange={(date) =>
                        formValues?.startTime
                          ? setFormValues({
                              ...formValues,
                              startDate: date,
                              startTime: new Date(
                                new Date(
                                  new Date(date).setHours(
                                    new Date(formValues?.startTime)?.getHours()
                                  )
                                ).setMinutes(
                                  new Date(formValues?.startTime)?.getMinutes()
                                )
                              ),
                            })
                          : setFormValues({
                              ...formValues,
                              startDate: date,
                            })
                      }
                    />
                    <label
                      htmlFor='startDate'
                      className='text-ezGray text-2xl cursor-pointer'
                    >
                      <FaRegCalendarAlt />
                    </label>
                  </div>
                </div>
                <div>
                  <h3 className='p-0 m-0 text-ezGray text-sm'>ENTER TIME</h3>
                  <div className='ez__FormInput w-full flex items-center justify-between border border-gray-400 rounded-md px-3 py-2 mb-4'>
                    <DatePicker
                      placeholderText='00:00'
                      selected={formValues?.startTime}
                      minDate={formValues?.startDate}
                      autoComplete='off'
                      onChange={(time) =>
                        formValues?.startDate
                          ? setFormValues({ ...formValues, startTime: time })
                          : setFormValues({
                              ...formValues,
                              startTime: time,
                              // startDate: time,
                              startDate: new Date(
                                new Date(time).setDate(
                                  new Date(time).getDate() + 1
                                )
                              ),
                            })
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={5}
                      timeCaption='Time'
                      dateFormat='h:mm aa'
                      id='startTime'
                    />
                    <label
                      htmlFor='startTime'
                      className='text-ezGray text-2xl cursor-pointer'
                    >
                      <BiTime />
                    </label>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                <div className='block relative w-full'>
                  <label
                    htmlFor='network'
                    className='p-0 m-0 text-ezGray text-sm py-1'
                  >
                    NETWORK TYPE
                  </label>
                  <div className='relative w-full text-sm sm:text-base text-ezGray'>
                    <label
                      htmlFor='network'
                      className='absolute right-3 top-4 cursor-pointer'
                    >
                      <MdOutlineKeyboardArrowDown className='w-7 h-7' />
                    </label>
                    <Select
                      className='basic-single mb-4'
                      classNamePrefix='select'
                      placeholder='Select Network*'
                      menuPlacement='top'
                      isSearchable={true}
                      styles={{
                        ...colourStyles,
                        menu: (provided) => ({ ...provided }),
                        container: (provided) => ({ ...provided }),
                      }}
                      name='network'
                      value={
                        formValues?.network && {
                          label: evNetworkTypes?.filter(
                            (network) => network?.value === formValues.network
                          )[0]?.title,
                          value: formValues?.network,
                        }
                      }
                      onChange={(e) =>
                        setFormValues({ ...formValues, network: e?.value })
                      }
                      options={evNetworkTypes?.map((network) => {
                        return {
                          label: network?.title,
                          value: network?.value,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className='block relative w-full'>
                  <label
                    htmlFor='connectorType'
                    className='p-0 m-0 text-ezGray text-sm py-1'
                  >
                    CONNECTOR TYPE
                  </label>
                  <div className='relative w-full text-sm sm:text-base text-ezGray'>
                    <label
                      htmlFor='connectorType'
                      className='absolute right-3 top-4 cursor-pointer '
                    >
                      <MdOutlineKeyboardArrowDown className='w-7 h-7' />
                    </label>
                    <Select
                      className='basic-single mb-4'
                      classNamePrefix='select'
                      placeholder='Select Connector*'
                      menuPlacement='top'
                      isSearchable={true}
                      styles={{
                        ...colourStyles,
                        menu: (provided) => ({ ...provided }),
                        container: (provided) => ({ ...provided }),
                      }}
                      name='connectorType'
                      value={
                        formValues?.connectorType && {
                          label: evConnectorTypes?.filter(
                            (connectorType) =>
                              connectorType?.value === formValues.connectorType
                          )[0]?.title,
                          value: formValues?.connectorType,
                        }
                      }
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          connectorType: e?.value,
                        })
                      }
                      options={evConnectorTypes?.map((connectorType) => {
                        return {
                          label: connectorType?.title,
                          value: connectorType?.value,
                        };
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-1 mb-5'>
                <div className='block w-full'>
                  <h6 className='text-sm text-ezGray flex items-center'>
                    FILTERS
                  </h6>
                  <div className='flex flex-row flex-wrap w-full gap-2'>
                    <RadioBtn
                      id='avoidTolls'
                      text='Avoid Tolls'
                      multiple={true}
                      classNames={'px-3 py-2 rounded-full text-xs'}
                      isSelected={formValues?.avoidTolls}
                      onClick={() =>
                        setFormValues({
                          ...formValues,
                          avoidTolls: !formValues?.avoidTolls,
                        })
                      }
                    />
                    <RadioBtn
                      id='avoidHighways'
                      text='Avoid Highways'
                      multiple={true}
                      classNames={'px-3 py-2 rounded-full text-xs'}
                      isSelected={formValues?.avoidHighways}
                      onClick={() =>
                        setFormValues({
                          ...formValues,
                          avoidHighways: !formValues?.avoidHighways,
                        })
                      }
                    />
                    <RadioBtn
                      id='allChargers'
                      text='Available Chargers'
                      multiple={true}
                      classNames={'px-3 py-2 rounded-full text-xs'}
                      isSelected={
                        formValues?.chargersType === 'availableChargers'
                          ? true
                          : false
                      }
                      onClick={() =>
                        setFormValues({
                          ...formValues,
                          chargersType:
                            formValues?.chargersType === 'allChargers'
                              ? 'availableChargers'
                              : 'allChargers',
                        })
                      }
                    />
                    <RadioBtn
                      id='compatible'
                      text='Compatible Chargers only'
                      multiple={true}
                      classNames={'px-3 py-2 rounded-full text-xs'}
                      isSelected={formValues?.compatible}
                      onClick={() =>
                        setFormValues({
                          ...formValues,
                          compatible: !formValues?.compatible,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='hidden  md:block'>
                <GrayBtn
                  isDisabled={!valid() && !formValues?.vehicle && !isLoading}
                  handleEvent={() => {
                    getDirections({
                      origin: formValues?.startFrom,
                      destination: formValues?.destination,
                    });
                  }}
                  classNameCondition={
                    valid() === true && formValues?.vehicle && !isLoading
                  }
                  text='Next Step'
                />
              </div>
              <div className='block md:hidden'>
                <GrayBtn
                  isDisabled={!valid() && !isLoading}
                  handleEvent={() => {
                    if (formValues?.vehicle) {
                      getDirections({
                        origin: formValues?.startFrom,
                        destination: formValues?.destination,
                      });
                    } else {
                      setCurrentTabs(['vehicle']);
                    }
                  }}
                  classNameCondition={valid() === true && !isLoading}
                  text='Next Step'
                />
              </div>
            </div>
          )}
          {currentTabs?.includes('vehicle') && (
            <div className='ez__StepForm w-full scroll-shadows col-span-4 md:max-h-full max-h-[32rem] md:overflow-visible overflow-y-auto'>
              <h2 className='p-0 m-0 font-bold mb-3  hidden md:block'>
                Your Vehicle
              </h2>
              <div className='ez__CarInfo h-full'>
                {vehicle ? (
                  <>
                    <PlanVehicleList
                      vehicle={vehicle}
                      setVehicle={setVehicle}
                      setIsVehicleChange={setIsVehicleChange}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      userVehicles={userVehicle}
                    />
                    <PlanVehicleCard
                      vehicle={vehicle}
                      setIsVehicleChange={setIsVehicleChange}
                      setIsVehicleModal={setIsVehicleModal}
                      setIsVehicleUpdate={setIsVehicleUpdate}
                      userVehicles={userVehicle}
                    />
                  </>
                ) : isApiLoading ? (
                  <div className='col-span-6 rounded-md text-ezGreen font-semibold w-full border border-gray-400 flex items-center justify-center h-20'>
                    <Spiner color='ezGreen' />
                  </div>
                ) : (
                  <button
                    onClick={() => setIsVehicleModal(true)}
                    className='col-span-6 rounded-md text-ezGreen font-semibold w-full border border-gray-400 flex items-center justify-center h-20'
                  >
                    Add Vehicle
                  </button>
                )}
                <div className='md:hidden mt-6'>
                  <GrayBtn
                    isDisabled={!valid() && !formValues?.vehicle && !isLoading}
                    handleEvent={() => {
                      getDirections({
                        origin: formValues?.startFrom,
                        destination: formValues?.destination,
                      });
                    }}
                    classNameCondition={
                      formValues?.vehicle && valid() && !isLoading
                    }
                    text='Next Step'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <AddVehicle
        isVehicleModal={isVehicleModal}
        setIsVehicleModal={setIsVehicleModal}
        getMyVehicles={addUserVehicles}
      />
      {isVehicleUpdate && (
        <UpdateVehicle
          editedVehicle={vehicle}
          isVehicleUpdate={isVehicleUpdate}
          setIsVehicleUpdate={setIsVehicleUpdate}
          getMyVehicles={updateUserVehicles}
        />
      )}
    </>
  );
};

export default PlanTripSteps1And2;
