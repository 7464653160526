import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addNotifications } from 'redux/dashbbaord/dashboardSlice';

const NotificationHandler = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBackgroundNotification = (event) => {
      const { data } = event;
      if (data && data.firebaseMessaging) {
        const payload = data.firebaseMessaging.payload;
        const notification = [
          {
            messageId: payload?.messageId,
            sender: payload?.from,
            title: payload?.notification?.title,
            message: payload?.notification?.body,
            url: payload?.data?.url,
            isRead: false,
            createdAt: Date.now(),
          },
        ];
        dispatch(addNotifications(notification));
      }
    };

    navigator.serviceWorker.addEventListener(
      'message',
      handleBackgroundNotification
    );

    return () => {
      navigator.serviceWorker.removeEventListener(
        'message',
        handleBackgroundNotification
      );
    };
  }, [dispatch]);

  return null; // NotificationHandler is just a utility component, it doesn't render anything
};

export default NotificationHandler;
