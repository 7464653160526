export const evConnectorTypes = [
  { id: 0, title: 'All', value: 'all' },
  { id: 1, title: 'NEMA 14-50', value: 'NEMA1450' },
  { id: 2, title: 'NEMA 5-15', value: 'NEMA515' },
  { id: 3, title: 'NEMA 5-20', value: 'NEMA520' },
  { id: 4, title: 'J1772', value: 'J1772' },
  { id: 5, title: 'CCS', value: 'J1772COMBO' },
  { id: 6, title: 'CHAdeMO', value: 'CHADEMO' },
  { id: 7, title: 'Tesla', value: 'TESLA' },
];

export const evNetworkTypes = [
  { id: 1, title: 'All', value: 'all' },
  { id: 2, title: 'AddÉnergie', value: 'AddÉnergie Technologies' },
  { id: 3, title: 'AmpUp', value: 'AMPUP' },
  { id: 4, title: 'BC Hydro', value: 'BCHYDRO' },
  { id: 5, title: 'Blink', value: 'Blink Network' },
  { id: 6, title: 'ChargeLab', value: 'CHARGELAB' },
  { id: 7, title: 'ChargePoint', value: 'ChargePoint Network' },
  { id: 8, title: 'CircleK Charge', value: 'CIRCLE_K' },
  { id: 9, title: 'CircleK/Couche-Tard Recharge', value: 'COUCHE_TARD' },
  { id: 10, title: 'Circuit électrique', value: 'Circuit électrique' },
  { id: 11, title: 'ECharge Network', value: 'eCharge Network' },
  { id: 12, title: 'Electrify America', value: 'Electrify America' },
  { id: 13, title: 'Electrify Canada', value: 'Electrify Canada' },
  { id: 14, title: 'EV Charging Solutions', value: 'EVCS' },
  { id: 15, title: 'EV Connect', value: 'EV Connect' },
  { id: 16, title: 'evGateway', value: 'EVGATEWAY' },
  { id: 17, title: 'EVgo', value: 'eVgo Network' },
  { id: 18, title: 'EV Range', value: 'EVRANGE' },
  { id: 19, title: 'FLASH', value: 'FLASH' },
  { id: 20, title: 'FLO', value: 'FLO' },
  { id: 21, title: 'FPL EVolution', value: 'FPLEV' },
  { id: 22, title: 'Francis', value: 'FCN' },
  { id: 23, title: 'Ivy', value: 'IVY' },
  { id: 24, title: 'Livingston Energy Group', value: 'LIVINGSTON' },
  { id: 25, title: 'Non-Networked', value: 'Non-Networked' },
  { id: 26, title: 'OpConnect', value: 'OpConnect' },
  { id: 27, title: 'Petro-Canada', value: 'PETROCAN' },
  { id: 28, title: 'PowerFlex', value: 'POWERFLEX' },
  { id: 29, title: 'Red E Charging', value: 'RED_E' },
  { id: 30, title: 'Rivian Adventure Network', value: 'RIVIAN_ADVENTURE' },
  { id: 31, title: 'Rivian Waypoints', value: 'RIVIAN_WAYPOINTS' },
  { id: 32, title: 'SemaConnect', value: 'SemaCharge Network' },
  { id: 33, title: 'Shell Recharge', value: 'SHELL_RECHARGE' },
  { id: 34, title: 'Sun Country Highway', value: 'Sun Country Highway' },
  { id: 35, title: 'Swtch Energy', value: 'SWTCH' },
  { id: 36, title: 'Tesla Destination', value: 'Tesla Destination' },
  { id: 37, title: 'Tesla Supercharger', value: 'Tesla' },
  { id: 38, title: 'Universal EV Chargers', value: 'UNIVERSAL' },
  { id: 39, title: 'Volta', value: 'Volta' },
  { id: 40, title: 'WAVE', value: 'WAVE' },
  { id: 41, title: 'Webasto', value: 'Webasto' },
  { id: 42, title: 'ZEF Network', value: 'ZEFNET' },
];

export const fuelTypes = [
  { id: 1, title: 'All Fuels', value: 'all' },
  { id: 2, title: 'Biodiesel (B20 and above)', value: 'BD' },
  { id: 3, title: 'Compressed Natural Gas (CNG)', value: 'CNG' },
  { id: 4, title: 'Electric', value: 'ELEC' },
  { id: 5, title: 'Ethanol (E85)', value: 'E85' },
  { id: 6, title: 'Hydrogen', value: 'HY' },
  { id: 7, title: 'Liquefied Natural Gas (LNG)', value: 'LNG' },
  { id: 8, title: 'Propane (LPG)', value: 'LPG' },
  { id: 9, title: 'Renewable Diesel (R20 and above)', value: 'RD' },
];

export const ownerTypes = [
  { id: 1, title: 'All', value: 'all' },
  { id: 2, title: 'Federal Government Owned', value: 'FG' },
  { id: 3, title: 'Jointly Owned', value: 'J' },
  { id: 4, title: 'Local/Municipal Government Owned', value: 'LG' },
  { id: 5, title: 'Privately Owned', value: 'P' },
  { id: 6, title: 'State/Provincial Government Owned', value: 'SG' },
  { id: 7, title: 'Utility Owned', value: 'T' },
];
