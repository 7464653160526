import { clockSvg, crossedClockSvg } from 'helper/helper';
import { useCountHook } from 'hooks/useCountHook';

const CardTimer = ({ startTime }) => {
  const [days, hours, minutes, seconds] = useCountHook(startTime);

  return (
    <>
      <div
        className={`w-fit rounded-md mb-5 md:mb-0 px-2 py-1 border flex items-center justify-center text-xs ${
          startTime
            ? 'bg-ezLightGreen border-ezGreen text-ezGreen'
            : 'bg-ezLightGray border-ezGray text-ezGray'
        }`}
      >
        <div className='text-center grid grid-cols-4 w-full flex-row gap-1 mt-1'>
          {startTime ? (
            clockSvg
          ) : (
            <span className='mb-0.5'>{crossedClockSvg}</span>
          )}{' '}
          <span className='col-span-3 flex flex-col justify-center pb-0.5'>
            {startTime
              ? `${
                  hours < 0 || minutes < 0 || seconds < 0
                    ? '0:0:0'
                    : `${hours}:${minutes}:${seconds}`
                }`
              : 'Not Scheduled'}
          </span>
        </div>
      </div>
    </>
  );
};

export default CardTimer;
