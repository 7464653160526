import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from 'helper/Loader';
import useApiHook from 'hooks/useApiHook';
import { checkUserAuthenticated, valdiationEmail } from 'helper/functionality';
import { MdMarkEmailRead } from 'react-icons/md';

const ContactUs = () => {
  const { auth } = useSelector((state) => state);
  const isUserAuthenticated = checkUserAuthenticated(auth?.userInfo);
  const { handleApiCall, isApiLoading } = useApiHook();

  const initialState = isUserAuthenticated
    ? {
        subject: '',
        message: '',
      }
    : {
        name: '',
        email: '',
        subject: '',
        message: '',
      };

  const [formValues, setFormValues] = useState(initialState);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (isUserAuthenticated) {
      if (!formValues?.message?.trim() || !formValues?.subject?.trim())
        return toast.error('All fields are required');
    } else {
      if (
        !formValues?.name?.trim() ||
        !formValues?.email?.trim() ||
        !formValues?.message?.trim() ||
        !formValues?.subject?.trim()
      )
        return toast.error('All fields are required');
      if (!valdiationEmail(formValues?.email))
        return toast.error('Email must be valid email address');
    }

    const result = await handleApiCall({
      method: 'post',
      url: '/contact',
      data: {
        name: isUserAuthenticated
          ? auth?.userInfo?.user?.name
          : formValues?.name,
        email: isUserAuthenticated
          ? auth?.userInfo?.user?.email
          : formValues?.email,
        ...formValues,
      },
    });
    if (result.status === 200) {
      toast.success(
        'Your query has been sent successfully. ezVOLTz will contact with you shortly.'
      );
      setFormValues(initialState);
    }
  };

  return (
    <div className='ez__Settings w-full bg-ezMidWhite px-4 py-10 md:p-10'>
      {isApiLoading && <Loader background='transparency' />}
      <div className='ez__Title w-full flex flex-row justify-between'>
        <div className='flex md:items-left justify-start mb-5 flex-col'>
          <h3 className='text-ezBlack font-semibold text-3xl mb-auto h-full'>
            Contact Us
          </h3>
          <h5 className='text-ezGray text-sm md:text-lg w-full font-semiBold mb-2 mt-1'>
            Please fill the following information and we will get back to you.
          </h5>
        </div>
        <div className='hidden md:flex mb-5 items-center justify-end ezInfo w-1/2'>
          <div className='ezInfoCard flex flex-row bg-white py-3 pl-4 pr-20 rounded-lg'>
            <a
              href='mailto:info@ezvoltz.com'
              className='w-14 h-14 mr-2 flex items-center justify-center rounded-lg bg-ezLightGreen text-ezGreen hover:border hover:text-ezGreen hover:border-ezGreen'
            >
              <MdMarkEmailRead className='w-8 h-8' />
            </a>
            <div className=''>
              <h6 className='text-ezGreen text-left text-base font-semibold'>
                Email
              </h6>
              <a
                href='mailto:info@ezvoltz.com'
                rel='noferrer'
                className='text-ezGray block text-left text-sm hover:text-ezGreen'
              >
                info@ezvoltz.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='card block w-full md:max-w-md lg:max-w-xl p-4 md:p-6 bg-white rounded-lg shadow-sm'>
        <div className='flex flex-col'>
          <form onSubmit={sendMessage} className='block w-full'>
            {!isUserAuthenticated && (
              <>
                <label
                  htmlFor='name'
                  className='text-ezGray text-sm md:text-base mb-1 block'
                >
                  Name
                </label>
                <input
                  type='text'
                  name='name'
                  value={formValues?.name}
                  onChange={(e) =>
                    setFormValues({ ...formValues, name: e?.target?.value })
                  }
                  required
                  placeholder='Enter Your Name'
                  className='w-full border border-gray-400 border-opacity-75 rounded-lg p-2.5 text-ezGray mb-5'
                />
                <label
                  htmlFor='email'
                  className='text-ezGray text-sm md:text-base mb-1 block'
                >
                  Email
                </label>
                <input
                  type='email'
                  name='email'
                  value={formValues?.email}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      email: e?.target?.value,
                    })
                  }
                  required
                  placeholder='Enter Your Email Address'
                  className='w-full border border-gray-400 border-opacity-75 rounded-lg p-2.5 text-ezGray mb-5'
                />
              </>
            )}
            <label
              htmlFor='subject'
              className='text-ezGray text-sm md:text-base mb-1 block'
            >
              SUBJECT
            </label>
            <input
              type='text'
              name='subject'
              value={formValues?.subject}
              onChange={(e) =>
                setFormValues({ ...formValues, subject: e?.target?.value })
              }
              required
              placeholder='Enter Your Subject'
              className='w-full border border-gray-400 border-opacity-75 rounded-lg p-2.5 text-ezGray mb-5'
            />
            <label
              htmlFor='message'
              className='text-ezGray text-sm md:text-base mb-1 block'
            >
              MESSAGE
            </label>
            <textarea
              name='message'
              required
              value={formValues?.message}
              onChange={(e) =>
                setFormValues({ ...formValues, message: e?.target?.value })
              }
              placeholder='How can we help?'
              className='w-full border border-gray-400 border-opacity-75 rounded-lg p-2.5 text-ezGray mb-5 h-32 md:h-36 lg:h-48 resize-none'
            ></textarea>
            <button
              type='submit'
              className='bg-ezGreen w-full py-2.5 text-white text-base rounded-lg border border-ezGreen hover:bg-transparent hover:text-ezGreen'
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
