import React from 'react';
import { Listbox } from '@headlessui/react';
import { arrowDown, arrowUp } from 'helper/helper';

const PlanVehicleList = ({
  vehicle,
  setVehicle,
  setFormValues,
  formValues,
  setIsVehicleChange,
  userVehicles,
}) => {
  return (
    <div className='block w-full col-span-6'>
      <div className='flex items-center justify-left mb-2'>
        <p className='text-ezGray text-sm'>SELECTED VEHICLE</p>
      </div>

      <Listbox
        value={vehicle}
        onChange={(vehicleInfo) => {
          setVehicle(vehicleInfo);
          setFormValues({
            ...formValues,
            vehicle: vehicleInfo,
          });
          setIsVehicleChange(false);
        }}
      >
        {({ open }) => (
          <>
            <div className='relative'>
              <Listbox.Button className='relative w-full cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-ezGreen focus:border-ezGreen sm:text-sm'>
                <span className='block truncate'>
                  {vehicle
                    ? `${vehicle.make.name} - ${vehicle.model.model}`
                    : 'Select a vehicle'}
                </span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  {open ? arrowUp : arrowDown}
                </span>
              </Listbox.Button>

              <Listbox.Options className='absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-50'>
                <div className='p-2'>
                  {userVehicles?.map((vehicleInfo) => (
                    <Listbox.Option
                      key={vehicleInfo._id}
                      className={({ active }) =>
                        `${
                          active
                            ? 'border border-ezGreen rounded-lg'
                            : 'border border-t-white border-x-white border-b-ezLightWhite rounded-lg'
                        }
                          text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9`
                      }
                      value={vehicleInfo}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className='flex items-center'>
                            <img
                              src={
                                vehicleInfo?.picture
                                  ? vehicleInfo?.picture
                                  : '/assets/images/carImg2.png'
                              }
                              alt={`${vehicleInfo.make.name} ${vehicleInfo.model.model}`}
                              className='flex-shrink-0 h-16 w-20'
                            />
                            <div className='ml-3'>
                              <p
                                className={`${
                                  selected ? 'font-semibold' : 'font-normal'
                                } text-sm`}
                              >
                                {vehicleInfo.model.model}
                              </p>
                              <p className='text-xs text-gray-500'>
                                Make: {vehicleInfo.make.name}
                              </p>
                            </div>
                          </div>

                          {selected ? (
                            <span
                              className={` text-ezGreen absolute inset-y-0 right-0 flex items-center pr-4`}
                            >
                              <svg
                                className='h-6 w-6'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                                aria-hidden='true'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M16.707 5.293a1 1 0 00-1.414 0L7 13.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l9-9a1 1 0 000-1.414z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default PlanVehicleList;
