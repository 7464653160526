import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MdCallSplit, MdCancel } from 'react-icons/md';
import Spiner from 'helper/Spiner';
import { androidUrl, iosUrl } from 'helper/functionality';
import useSaasChargeProdApiHook from 'hooks/saasCharge/prod/useSaasChargeProdApiHook';

export default function SaasChargeDetailDrawer({
  sassChargeDetailData,
  isSassChargeDetail,
  handleDirection,
  getDirections,
}) {
  const [csoConnectorDetail, setCsoConnectorDetail] = useState(null);
  const [tabView, setTabView] = useState('detail');
  const { handleSassApiCall, isSassLoading } = useSaasChargeProdApiHook();
  const getCSOStationDetail = async () => {
    const url = `/external/connectors/${sassChargeDetailData?.stationId}/status`;
    const result = await handleSassApiCall({
      method: 'get',
      url,
    });
    if (result.status === 200) {
      setCsoConnectorDetail(result?.data);
    }
  };

  useEffect(() => {
    if (isSassChargeDetail) getCSOStationDetail();
  }, [isSassChargeDetail]);

  return (
    <Transition.Root show={isSassChargeDetail || false} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleDirection}>
        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden bg-ezBlack bg-opacity-50'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
                afterLeave={handleDirection}
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-2xl shadow-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl relative'>
                    <div className='absolute top-3 right-3 z-50'>
                      <button
                        type='button'
                        className='rounded-full bg-transparent text-ezBlack'
                        onClick={handleDirection}
                      >
                        <MdCancel className='h-8 w-8' aria-hidden='true' />
                      </button>
                    </div>
                    <div className='divide-y divide-gray-200 relative'>
                      {isSassLoading ? (
                        <div className='h-56 w-full'>
                          <Spiner color='ezGreen' />
                        </div>
                      ) : (
                        <dl className='space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200'>
                          <div className='imgDiv w-full'>
                            <img
                              src='/assets/images/stationDetailImg2.png'
                              alt='stationDetailImg'
                              className='w-full h-32 md:h-80 object-cover'
                            />
                          </div>
                          <div className='px-6 md:flex items-center justify-between w-full sm:py-5'>
                            <div className='block min-w-max'>
                              <h4 className='text-ezBlack text-base'>
                                {sassChargeDetailData?.stationName}
                              </h4>
                              <p className='text-ezGray text-xs'>
                                Address:{' '}
                                {`${sassChargeDetailData?.stationStreet}, ${sassChargeDetailData?.stationPostalCode} ${sassChargeDetailData?.stationCity}`}
                              </p>
                            </div>
                            <div className='md:flex items-center mt-3 min-w-max'>
                              <button
                                type='button'
                                onClick={() =>
                                  getDirections({
                                    ...sassChargeDetailData,
                                    latitude: +sassChargeDetailData?.gpsLat,
                                    longitude: +sassChargeDetailData?.gpsLong,
                                  })
                                }
                                className='flex items-center justify-center mb-3 md:mb-0 md:mr-3 bg-ezGreen text-white rounded-full w-8 md:w-10 h-8 md:h-10 cursor-pointer'
                              >
                                <MdCallSplit className='h-5 md:h-6 w-5 md:w-6' />
                              </button>
                            </div>
                          </div>
                          <div className='ezDetailTabView'>
                            <div className='ezTabBtns grid grid-cols-2 items-center border-t border-b border-gray-300'>
                              <button
                                onClick={() => setTabView('detail')}
                                className={`${
                                  tabView === 'detail'
                                    ? 'text-ezGreen border-ezGreen'
                                    : 'border-transparent text-ezGray'
                                } text-center w-full p-2 border-b hover:border-ezGreen text-sm md:text-base hover:text-ezGreen`}
                              >
                                Details
                              </button>
                              <button
                                onClick={() => setTabView('connectors')}
                                className={`${
                                  tabView === 'connectors'
                                    ? 'text-ezGreen border-ezGreen'
                                    : 'border-transparent text-ezGray'
                                } text-center w-full p-2 border-b hover:border-ezGreen text-sm md:text-base hover:text-ezGreen`}
                              >
                                Connectors
                              </button>
                            </div>
                            {tabView === 'detail' && (
                              <div className='ezTabDiv detailViewDiv'>
                                <div className='block w-full pt-5 pb-2'>
                                  <h5 className='text-ezGreen font-semibold pl-4 md:pl-6 mb-3'>
                                    Availability
                                  </h5>
                                  <div className='sm:flex pl-4 md:px-6 mb-1'>
                                    <dt className='text-sm font-medium text-ezBlack sm:w-40 sm:flex-shrink-0 lg:w-48'>
                                      CSO:
                                    </dt>
                                    <dd className='mt-1 text-sm text-ezGray sm:col-span-2 sm:mt-0 sm:ml-6'>
                                      {sassChargeDetailData?.csoName}
                                    </dd>
                                  </div>
                                  <div className='sm:flex pl-4 md:px-6 mb-1'>
                                    <dt className='text-sm font-medium text-ezBlack sm:w-40 sm:flex-shrink-0 lg:w-48'>
                                      Access:
                                    </dt>
                                    <dd className='capitalize mt-1 text-sm sm:col-span-2 sm:mt-0 sm:ml-6'>
                                      {sassChargeDetailData?.isAvailable ? (
                                        <span className='inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                          Available
                                        </span>
                                      ) : (
                                        <span className='inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'>
                                          Unavailable
                                        </span>
                                      )}
                                      {/* {csoConnectorDetail?.status?.toLowerCase() ===
                                      'available' ? (
                                        <span className='inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                          {csoConnectorDetail?.status}
                                        </span>
                                      ) : csoConnectorDetail?.status?.toLowerCase() ===
                                        'unavailable' ? (
                                        <span className='inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'>
                                          {csoConnectorDetail?.status}
                                        </span>
                                      ) : csoConnectorDetail?.status?.toLowerCase() ===
                                        'charging' ? (
                                        <span className='inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20'>
                                          {csoConnectorDetail?.status}
                                        </span>
                                      ) : (
                                        <span className='inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20'>
                                          {csoConnectorDetail?.status}
                                        </span>
                                      )} */}
                                    </dd>
                                  </div>
                                </div>
                                <div className='block w-full py-5'>
                                  {/* <h5 className='text-ezGreen font-semibold pl-4 md:pl-6 mb-3'>
                                    About Station
                                  </h5>
                                  <div className='sm:flex pl-4 md:px-6 mb-1'>
                                    <dt className='text-sm font-medium text-ezBlack sm:w-40 sm:flex-shrink-0 lg:w-48'>
                                      Operator Name:
                                    </dt>
                                    <dd className='mt-1 text-sm text-ezGray sm:col-span-2 sm:mt-0 sm:ml-6'>
                                      {sassChargeDetailData?.stationOwner}
                                    </dd>
                                  </div>

                                  <div className='sm:flex pl-4 md:px-6 mb-1'>
                                    <dt className='text-sm font-medium text-ezBlack sm:w-40 sm:flex-shrink-0 lg:w-48'>
                                      Operator ID:
                                    </dt>
                                    <dd className='mt-1 text-sm text-ezGray sm:col-span-2 sm:mt-0 sm:ml-6'>
                                      {
                                        sassChargeDetailData?.stationSerialNumber
                                      }
                                    </dd>
                                  </div> */}
                                  <div className='sm:flex pl-4 md:px-6 mb-1'>
                                    <dt className='text-sm font-medium text-ezBlack sm:w-40 sm:flex-shrink-0 lg:w-48'>
                                      Note:
                                    </dt>
                                    <dd className='mt-1 text-sm text-ezGray sm:col-span-2 sm:mt-0 sm:ml-6'>
                                      <p className='mb-3'>
                                        Charging is available through the
                                        ezVOLTz App. Download the free ezVOLTz
                                        app
                                      </p>
                                      <div className='flex items-center space-x-3'>
                                        <a
                                          href={androidUrl}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='w-auto h-6 md:h-8 rounded-md overflow-hidden'
                                        >
                                          <img
                                            src='/assets/images/googleBtn.png'
                                            alt='Google'
                                            className='w-auto h-full'
                                          />
                                        </a>
                                        <a
                                          href={iosUrl}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='w-auto h-6 md:h-8 rounded-md overflow-hidden'
                                        >
                                          <img
                                            src='/assets/images/appleBtn.png'
                                            alt='Google'
                                            className='w-auto h-full'
                                          />
                                        </a>
                                      </div>
                                    </dd>
                                  </div>
                                </div>
                              </div>
                            )}
                            {tabView === 'connectors' && (
                              <div className='ezTabDiv connectorViewDiv'>
                                <div className='block w-full py-5'>
                                  <h5 className='text-ezGreen font-semibold pl-4 md:pl-6 mb-3'>
                                    Connector Details
                                  </h5>
                                  {csoConnectorDetail?.map(
                                    (connector, index) => (
                                      <div
                                        key={index}
                                        className='sm:flex sm:flex-col pl-4 md:px-6 mb-1 border border-gray-300 my-2 mx-4 rounded-lg p-2 bg-gray-50/20'
                                      >
                                        <div className='text-sm font-medium text-ezBlack w-full mb-2'>
                                          Connector {index + 1}:
                                        </div>
                                        <div className='grid md:grid-cols-3 grid-cols-1'>
                                          <div className='flex items-center'>
                                            <dt className='text-xs font-medium text-ezBlack'>
                                              Plug Type:
                                            </dt>
                                            <dd className='mt-1 text-xm text-ezGray sm:col-span-2 sm:mt-0 ml-4 md:ml-5'>
                                              {connector?.plugType}
                                            </dd>
                                          </div>
                                          <div className='flex items-center'>
                                            <dt className='text-xs font-medium text-ezBlack'>
                                              Power:
                                            </dt>
                                            <dd className='mt-1 text-xm text-ezGray sm:col-span-2 sm:mt-0 ml-4 md:ml-5'>
                                              {connector?.power} kWh
                                            </dd>
                                          </div>
                                          <div className='flex items-center'>
                                            <dt className='text-xs font-medium text-ezBlack'>
                                              Status:
                                            </dt>
                                            <dd className='mt-1 text-xm text-ezGray sm:col-span-2 sm:mt-0 ml-4 md:ml-6'>
                                              {connector?.status?.toLowerCase() ===
                                              'available' ? (
                                                <span className='inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                                  {connector?.status}
                                                </span>
                                              ) : connector?.status?.toLowerCase() ===
                                                'unavailable' ? (
                                                <span className='inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20'>
                                                  {connector?.status}
                                                </span>
                                              ) : connector?.status?.toLowerCase() ===
                                                'charging' ? (
                                                <span className='inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20'>
                                                  {connector?.status}
                                                </span>
                                              ) : (
                                                <span className='inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20'>
                                                  {connector?.status}
                                                </span>
                                              )}
                                            </dd>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </dl>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
