import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import TripEVMarker from "components/home/TripEVMarker";
import UpComingTimer from "components/upComingTripDetail/UpComingTimer";
import { dateComing } from "helper/functionality";
import Loader from "helper/Loader";
import Spiner from "helper/Spiner";
import useApiHook from "hooks/useApiHook";
import DeleteModal from "modals/common/DeleteModal";
import UpdatePlanTrip from "modals/planTrip/UpdatePlanTrip";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getTripDetailDirections } from "utls/apiCalls/googleApi";
import { instanceNREL } from "utls/instances";

const UpcomingTripDetail = () => {
  const { auth } = useSelector((state) => state);
  const { tripId } = useParams();
  const navigate = useNavigate();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isModal, setIsModal] = useState(false);
  const [isDelLoading, setIsDelLoading] = useState(false);
  const [isTripUpdate, setIsTripUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tripDetail, setTripDetail] = useState(null);
  const [directionUrl, setDirectionUrl] = useState("");
  const [directions, setDirections] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [allEVs, setAllEVs] = useState(null);

  const getDirections = async ({
    origin,
    destination,
    stops,
    network,
    chargersType,
    connector,
  }) => {
    setIsLoading(false);
    const result = await getTripDetailDirections({
      origin,
      destination,
      stops: stops?.map((stop) => stop?.name),
    });
    if (result.isSuccess) {
      setDirections(result?.data);
      let lineString = result?.data.routes[0].overview_path
        ?.map((point) => {
          return `${point.lng()} ${point.lat()}`;
        })
        ?.join(", ");
      getAllEV({
        directions: result?.data,
        network,
        chargersType,
        connector,
        lineString,
        retryTime: 0,
      });
      let url = "https://www.google.com/maps/dir/";
      result?.data?.routes[0]?.legs?.map((direction, ind) => {
        if (ind === 0) {
          url += `${direction?.start_location?.lat()} ${direction?.start_location?.lng()}/${direction?.end_location?.lat()} ${direction?.end_location?.lng()}`;
        } else if (ind === result?.data?.routes[0]?.legs?.length - 1) {
          url += `/${direction?.end_location?.lat()} ${direction?.end_location?.lng()}/&dirflg=d`;
        } else {
          url += `/${direction?.end_location?.lat()} ${direction?.end_location?.lng()}`;
        }
      });
      setDirectionUrl(url);
    }
  };

  const getSingleTrip = async (userId) => {
    const result = await handleApiCall({
      method: "get",
      url: `/trip/${userId}/${tripId}`,
    });
    if (result?.status === 200) {
      setTripDetail(result?.data?.trip);
      getDirections(result?.data?.trip);
      setRemainingTime(
        dateComing(
          result?.data?.trip?.startDate && result?.data?.trip?.startTime
            ? result?.data?.trip?.startTime
            : result?.data?.trip?.startDate &&
              new Date(result?.data?.trip?.startDate).getDate() ===
                new Date().getDate() &&
              new Date(result?.data?.trip?.startDate).getMonth() ===
                new Date().getMonth() &&
              new Date(result?.data?.trip?.startDate).getFullYear() ===
                new Date().getFullYear()
            ? new Date(new Date(new Date().setHours(23)).setMinutes(59))
            : result?.data?.trip?.startDate
        )
      );
    }
  };

  const deleteTrip = async () => {
    setIsDelLoading(true);
    const result = await handleApiCall({
      method: "delete",
      url: `/trip/${auth?.userInfo?.user?._id}/${tripId}`,
    });
    setIsDelLoading(false);
    if (result?.status === 200) {
      setIsModal(false);
      toast.success(result?.data?.message);
      navigate("/my-trips");
    }
  };

  const getAllEV = async ({
    network,
    chargersType,
    connector,
    lineString,
    retryTime = 0,
  }) => {
    if (retryTime > 1) return;
    try {
      let url = `alt-fuel-stations/v1/nearby-route.json?route=LINESTRING(${lineString})&ev_network=${network}&ev_connector_type=${connector}&status=${
        chargersType === "availableChargers" ? "E" : "all"
      }&distance=1`;
      const result = await instanceNREL.get(url);
      if (result.status === 200) setAllEVs(result?.data);
    } catch (error) {
      setIsLoading(false);
      if (error?.code === "ERR_NETWORK") {
        let lineString = directions.routes[0].overview_path
          ?.map((point, index) => {
            if (index === directions.routes[0].overview_path?.length - 1)
              return `${point.lng()} ${point.lat()}`;
            else if (index % 2 === 0) return `${point.lng()} ${point.lat()}, `;
          })
          .join("");
        return getAllEV({
          network: tripDetail?.network,
          chargersType: tripDetail?.chargersType,
          connector: tripDetail?.connector,
          lineString,
          retryTime: retryTime + 1,
        });
      }
      toast.error("No pins found against this route.");
    }
  };

  useEffect(() => {
    if (auth?.userInfo?.user?._id) getSingleTrip(auth?.userInfo?.user?._id);
  }, []);

  return (
    <div
      className={`ez__PreviousTripDetail flex flex-col justify-center w-full bg-ezMidWhite py-10 px-4 md:p-10 ${
        isApiLoading || isLoading ? "h-full" : ""
      }`}
    >
      {isApiLoading || isLoading ? (
        <Spiner color="ezGreen" />
      ) : (
        <>
          {isDelLoading && <Loader background="transparency" />}
          <div className="ez__Title w-full flex md:items-center justify-between mb-6 flex-col md:flex-row">
            <h3 className="text-ezBlack font-semibold md:text-2xl lg:text-3xl mb-3 md:mb-0">
              Trip to {tripDetail?.destination?.text}
            </h3>
            <div className="ez__TripDetailCard min-w-fit flex flex-col px-4 py-2 border-2 shadow-md rounded-md bg-white">
              <h4 className="text-ezGray text-center text-lg mb-2 font-semibold">
                Remaining Time
              </h4>

              {remainingTime?.includes("day") ||
              remainingTime?.includes("month") ||
              remainingTime?.includes("year") ? (
                <p className="flex items-center justify-center w-full text-ezGray text-base">
                  <AiOutlineClockCircle className="mr-2" />{" "}
                  {tripDetail?.startDate ? remainingTime : "Not Scheduled"}{" "}
                  {tripDetail?.startDate && "To Go"}
                </p>
              ) : (
                <UpComingTimer
                  startTime={
                    tripDetail?.startDate && tripDetail?.startTime
                      ? tripDetail?.startTime
                      : tripDetail?.startDate
                      ? new Date(
                          new Date(new Date().setHours(23)).setMinutes(59)
                        )
                      : null
                  }
                />
              )}
            </div>
            <div className="ez__Tabs w-max flex items-center ml-auto mt-4 md:m-0">
              <button
                type="button"
                onClick={() => setIsTripUpdate(true)}
                className="bg-ezGreen text-white rounded-lg mr-3 text-sm md:text-base py-2.5 px-3 lg:px-6 border border-ezGreen hover:bg-transparent hover:text-ezGreen"
              >
                Edit Trip
              </button>
              <button
                type="button"
                onClick={() => setIsModal(true)}
                className="bg-ezRed text-white rounded-lg text-sm md:text-base py-2.5 px-3 lg:px-6 border border-ezRed hover:bg-transparent hover:text-ezRed"
              >
                Delete Trip
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-3 w-full h-full">
            <div className="ez__TripDetailCard w-full flex flex-col gap-y-3 bg-transparent md:col-span-4 lg:col-span-3 overflow-ellipsis ...">
              <div className="p-6 shadow-md border rounded-lg bg-white">
                <h4 className="text-ezGray text-xl mb-3 font-semibold">
                  TRIP DETAILS
                </h4>
                <div className="text-center flex flex-col justify-center items-center">
                  <div className="flex flex-row justify-between my-4 text-ezGreen w-full items-center">
                    <div className="flex flex-col text-left max-w-xs">
                      <p className="text-xs">FROM</p>
                      <p className="text-md font-semibold truncate">
                        {tripDetail?.origin?.text?.slice(0, 10)}...
                      </p>
                    </div>
                    <div className="flex-grow mx-2 relative">
                      <div className="dotted-line"></div>
                      <img
                        src="/assets/images/tripPointsCar.png"
                        alt="Car Icon"
                        className="car-icon"
                      />
                    </div>
                    <div className="flex flex-col text-left max-w-xs">
                      <p className="text-xs">TO</p>
                      <p className="text-md font-semibold truncate">
                        {tripDetail?.destination?.text?.slice(0, 10)}...
                      </p>
                    </div>
                  </div>
                  <hr className="w-full bg-ezBlack" />
                  <div className="flex flex-row flex-wrap gap-y-2 gap-x-2 xl:gap-x-8 mt-3 md:mt-0 pt-4 justify-between w-full">
                    <div className="text-ezGray mr-2 flex flex-col justify-start items-start mb-1">
                      <p className="text-xs">TOTAL MILES</p>
                      <p className="text-md font-semibold">
                        {tripDetail?.distance}
                      </p>
                    </div>
                    <div className="text-ezGray mr-2 flex flex-col justify-start items-start mb-1">
                      <p className="text-xs">AVERAGE SPEED</p>
                      <p className="text-md font-semibold">
                        {tripDetail?.speed}
                      </p>
                    </div>
                    <div className="text-ezGray mr-2 flex flex-col justify-start items-start mb-1">
                      <p className="text-xs">ENERGY</p>
                      <p className="text-md font-semibold">
                        {tripDetail?.energy}
                      </p>
                    </div>
                    <div className="text-ezGray mr-2 flex flex-col justify-start items-start mb-1">
                      <p className="text-xs">DATE</p>
                      <p className="text-md font-semibold">
                        {tripDetail?.startDate
                          ? moment(tripDetail?.startDate).format("DD MMMM YYYY")
                          : moment(tripDetail?.actualStartDateTime).format(
                              "DD MMMM YYYY"
                            )}
                      </p>
                    </div>
                    <div className="text-ezGray mr-2 flex flex-col justify-start items-start mb-1">
                      <p className="text-xs">TIME</p>
                      <p className="text-md font-semibold">
                        {new Date(tripDetail?.startTime).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                    </div>
                    <div className="text-ezGray mr-2 flex flex-col justify-start items-start mb-1">
                      <p className="text-xs">STOPS</p>
                      <p className="text-md font-semibold">
                        {tripDetail?.stops?.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ez__CarInfo flex flex-col p-6 rounded-lg shadow-md broder bg-white">
                <h4 className="text-ezGray text-xl mb-3 font-semibold">
                  VEHICLE DETAILS
                </h4>
                <h3 className="text-ezDarkGray text-lg font-bold">
                  {tripDetail?.vehicleId?.make?.models[0]?.model}
                </h3>
                <p className="text-black text-xs block w-full mb-1 font-semibold">
                  <span className="mr-2 font-normal"> Make:</span>
                  {tripDetail?.vehicleId?.make?.make}
                </p>
                <p className="text-black text-xs block w-full font-semibold">
                  <span className="mr-2 font-normal"> Range:</span>
                  {tripDetail?.vehicleId?.range}
                </p>
                <div className="flex flex-row justify-center">
                  <img
                    src={
                      tripDetail?.vehicleId?.picture
                        ? tripDetail?.vehicleId?.picture
                        : "/assets/images/carImg2.png"
                    }
                    alt="Car Wheel"
                    className="w-auto h-56 object-cover rounded-lg col-span-2"
                  />
                </div>
              </div>
            </div>
            <div className="tripMap shadow-md broder overflow-hidden rounded-lg w-full md:min-h-[500px] lg:min-h-max md:col-span-6 lg:col-span-9">
              {directions && (
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  options={{ minZoom: 4, maxZoom: 18 }}
                >
                  {directions && (
                    <DirectionsRenderer
                      directions={directions}
                      options={{
                        polylineOptions: {
                          strokeOpacity: 1,
                          strokeColor: "#228B22",
                          strokeWeight: 5,
                        },
                      }}
                    />
                  )}

                  {allEVs?.fuel_stations?.length > 0 &&
                    allEVs?.fuel_stations?.map((ev) => (
                      <TripEVMarker
                        key={`EVDetailPointsKey${
                          ev?.id
                        }--${new Date().getTime()}`}
                        ev={ev}
                      />
                    ))}
                </GoogleMap>
              )}
            </div>
          </div>
        </>
      )}
      {isTripUpdate && (
        <UpdatePlanTrip
          isTripUpdate={isTripUpdate}
          setIsTripUpdate={setIsTripUpdate}
          tripDetail={tripDetail}
        />
      )}
      {isModal && (
        <DeleteModal
          isModal={isModal}
          isLoading={isDelLoading}
          handleCloseModal={() => setIsModal(false)}
          title="Delete Trip Confirmation"
          description="Are you sure you want to delete this trip?"
          handleDelete={deleteTrip}
        />
      )}
    </div>
  );
};

export default UpcomingTripDetail;
