import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FiAlertTriangle } from "react-icons/fi";
import Loader from "helper/Loader";
import { BsTrashFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";

export default function AuthModel({
  isModal,
  isLoading,
  handleCloseModal,
  handleResendEmail,
  title,
  descriptionBefore,
  descriptionAfter,
  userEmail,
  resendTextBefore,
  resendTextAfter,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isModal || false} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={handleCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0">
            {isLoading && <Loader background="transparency" />}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full relative transform overflow-hidden bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm p-5 rounded-lg">
                <button
                  className="absolute right-0 top-0 m-4  z-50 "
                  onClick={handleCloseModal}
                >
                  <MdClose className="w-6 h-6 cursor-pointer" color="#D3D3D3" />
                </button>
                <div className="sm:flex sm:flex-col sm:items-center sm:justify-center sm:text-center">
                  <div className="flex flex-col items-center justify-center w-full h-full relative">
                    <img
                      src="/assets/svgs/iconBadge.svg"
                      alt="iconBadge"
                      className="w-10 md:w-16 h-auto mb-2"
                    />
                    <h3 className="text-ezBlack font-bold text-lg md:text-xl mb-2">
                      {title}
                    </h3>
                    <p className="text-ezNuturalGray text-base text-center mb-3">
                      {descriptionBefore}{" "}
                      <b className="text-ezGreen font-semibold">
                        {userEmail || ""}{" "}
                      </b>
                      {descriptionAfter}
                    </p>

                    <p className="text-ezNuturalGray text-base text-center">
                      {resendTextBefore}
                      <b
                        className="text-ezGreen font-semibold hover:cursor-pointer"
                        onClick={handleResendEmail}
                      >
                        {" "}
                        Click here{" "}
                      </b>
                      {resendTextAfter}
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
