import { Fade } from 'react-reveal';
import {
  androidUrl,
  animationProps,
  iosUrl,
  planTripSteps,
} from 'helper/functionality';

export default function RangeHalo() {
  return (
    <div className='ezRangeHalo bg-ezMidWhite pt-5 pb-14'>
      <div className='mx-auto max-w-7xl px-6 lg:px-4'>
        <div className='w-full grid items-center grid-cols-1 md:grid-cols-5 gap-16 md:gap-0 mt-8'>
          <div className='md:col-span-3 flex flex-col md:items-start md:px-20'>
            <Fade {...animationProps}>
              <h2 className='text-ezBlack text-4xl md:text-6xl font-bold'>
                Range Halo
              </h2>
            </Fade>
            <Fade {...animationProps}>
              <p className='text-ezBlack text-base md:text-2xl md:mt-4 md:mb-4'>
                Suggested Recharging Stops along your route based on your EV’s
                mileage
              </p>
            </Fade>
            <Fade>
              <p className='text-ezBlack text-opacity-50 text-md md:text-xl md:mt-8'>
                Experience the power of our Range Halo™ feature, a revolutionary
                tool based on your vehicle’s mileage that guides you to the
                ideal charging spots along your route, reducing your range
                anxiety. We’ll help you plan a trip that anticipates your next
                charging stop! Tailor your search with our intelligent filters
                to discover stations that perfectly match your specific needs,
                whether that's compatibility with your vehicle, preferred
                charger type, or favorite EV charging network. Plus, stay
                informed with real-time availability updates to see if your
                chosen station is ready for use or currently occupied.
              </p>
            </Fade>
          </div>
          <div className='ezFilterImg w-full flex justify-center md:col-span-2'>
            <Fade {...animationProps}>
              <img
                src='/assets/images/RangeHalo.png'
                className='w-auto md:w-full mx-auto md:ml-0 h-96 md:h-auto block'
                alt='Filter Screen'
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
