import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import Spiner from "helper/Spiner";
import { MdCancel } from "react-icons/md";
import { FileUploader } from "react-drag-drop-files";
import { colourStyles } from "helper/helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useApiHook from "hooks/useApiHook";
import { BsArrowLeft } from "react-icons/bs";

export default function UpdateVehicle({
  editedVehicle,
  isVehicleUpdate,
  setIsVehicleUpdate,
  getMyVehicles,
}) {
  const { auth } = useSelector((state) => state);
  const cancelButtonRef = useRef(null);
  const { handleApiCall, isApiLoading } = useApiHook();
  const [manufactureList, setManufactureList] = useState([]);
  const [models, setModels] = useState([]);
  const [file, setFile] = useState(null);
  const [isImagePreview, setIsImagePreview] = useState({
    isPreview: true,
    url: editedVehicle?.picture,
  });
  const [formValues, setFormValues] = useState({
    make: editedVehicle?.make?._id,
    model: editedVehicle?.model?._id,
    range: editedVehicle?.range,
  });

  const handleImagePreview = (file) => {
    setFile(file);
    const url = URL.createObjectURL(file);
    setIsImagePreview({
      isPreview: true,
      url,
    });
  };

  const handleReset = () => {
    setFormValues({
      make: null,
      model: null,
      range: null,
    });
    setIsImagePreview({
      isPreview: false,
      url: "",
    });
    setFile(null);
    setManufactureList([]);
    setModels([]);
  };

  const getVehicleManufacture = async () => {
    const result = await handleApiCall({
      method: "get",
      url: "/vehicle-manufacture",
    });
    if (result?.status === 200) {
      setManufactureList(result?.data?.vehicleManufactur);
      setModels(
        result?.data?.vehicleManufactur?.filter(
          (make) => make?._id === editedVehicle?.make?._id
        )[0]?.models || []
      );
    }
  };

  const handleUpdateVehicle = async () => {
    if (!formValues?.make || !formValues?.model)
      return toast.error("All fields are required");
    let data;
    let headers = {
      "Content-Type": "application/json",
    };
    if (file) {
      data = new FormData();
      data.append("make", formValues?.make);
      data.append("model", formValues?.model);
      data.append(
        "range",
        formValues?.range ||
          models?.filter((model) => model?._id === formValues?.model)[0]?.range
      );
      data.append("image", file);
      headers = {
        "Content-Type": "multipart/form-data",
      };
    } else {
      data = {
        make: formValues?.make,
        model: formValues?.model,
        range:
          formValues?.range ||
          models?.filter((model) => model?._id === formValues?.model)[0]?.range,
      };
    }

    const result = await handleApiCall({
      method: "patch",
      url: `/vehicle/${auth?.userInfo?.user?._id}/${editedVehicle?._id}`,
      data,
      headers,
    });
    if (result?.status === 200) {
      toast.success(result?.data?.message);
      setIsVehicleUpdate(false);
      handleReset();
      getMyVehicles(result?.data?.vehicle);
    }
  };

  useEffect(() => {
    getVehicleManufacture();
  }, []);

  return (
    <div className="ez__MyVehicle md:w-3/4 w-full h-full bg-ezMidWhite">
      <div className="ez__Title w-full flex md:items-center justify-start mb-8 flex-col md:flex-row">
        <span className="flex justify-between items-center text-ezBlack font-semibold md:text-3xl mb-2  text-lg md:mb-0 md:border-none border-b-2 border-gray-300 py-2">
          <button onClick={() => setIsVehicleUpdate(false)}>
            <BsArrowLeft className="md:mr-5 md:ml-2 hover:cursor-pointer" />
          </button>
          Update Vehicle
          <div />
        </span>
      </div>

      <div className="ez__Form w-full grid grid-cols-1 md:grid-cols-2 gap-x-5">
        <div className="p-6 bg-white rounded-lg h-fit">
          <div className="block w-full text-ezGray">
            <h2 className="font-semiBold text-ezDarkGray text-lg mb-3">
              SELECT YOUR VEHICLE DETAILS
            </h2>
            <h4 className="mb-1">MAKE</h4>
            <Select
              className={`basic-single mb-4 ${
                manufactureList?.length <= 0 ? "disableSelect" : ""
              }`}
              classNamePrefix="select"
              placeholder="Select Make*"
              isClearable={true}
              isSearchable={true}
              name="make"
              styles={colourStyles}
              value={
                formValues?.make && {
                  label: manufactureList?.filter(
                    (vehicle) => vehicle?._id === formValues?.make
                  )[0]?.make,
                  value: formValues?.make,
                }
              }
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  make: e?.value,
                  model: "",
                  range: "",
                });
                setModels(
                  manufactureList?.filter((model) => model?._id === e?.value)[0]
                    ?.models || []
                );
              }}
              options={manufactureList?.map((vehicle) => {
                return {
                  label: vehicle?.make,
                  value: vehicle?._id,
                };
              })}
            />
            <h4 className="mb-1">MODEL</h4>
            <Select
              className={`basic-single mb-4 ${
                models?.length <= 0 ? "disableSelect" : ""
              }`}
              classNamePrefix="select"
              placeholder="Select Model*"
              isClearable={true}
              isSearchable={true}
              styles={colourStyles}
              name="model"
              value={
                formValues?.model && {
                  label: models?.filter(
                    (model) => model?._id === formValues?.model
                  )[0]?.model,
                  value: formValues?.model,
                }
              }
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  model: e?.value,
                  range: "",
                });
              }}
              isDisabled={models?.length <= 0 ? true : false}
              options={models?.map((model) => {
                return {
                  label: model?.model,
                  value: model?._id,
                };
              })}
            />
            <h4 className="mb-1">RANGE</h4>
            <input
              type="number"
              placeholder="Vehicle Range"
              value={formValues?.range || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  range: e?.target?.value.slice(0, 4),
                })
              }
              className="w-full block border border-ezMidGray rounded-lg text-sm text-ezBlack placeholder-gray-500 p-2.5 mb-6"
            />
            <div className="block">
              <button
                type="submit"
                disabled={isApiLoading}
                onClick={handleUpdateVehicle}
                className={`py-2 text-base text-white border border-ezGreen bg-ezGreen rounded-lg block w-full text-center ${
                  isApiLoading ? "cursor-wait" : "hover:bg-transparent"
                } hover:text-ezGreen`}
              >
                {isApiLoading ? <Spiner color="white" /> : "Update Vehicle"}
              </button>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col justify-left p-6 bg-white rounded-lg w-auto max-h-[600px] ${
            isImagePreview?.isPreview && isImagePreview?.url ? "h-fit mb-5" : ""
          }`}
        >
          <div className="ez__VehicleImage relative block w-full pb-4 md:pb-0 h-full">
            <h2 className="font-semiBold text-ezDarkGray text-lg mb-3">
              UPLOAD IMAGE
            </h2>
            {isImagePreview?.isPreview && isImagePreview?.url ? (
              <div className="relative border-2 border-ezGray rounded-xl w-full h-full overflow-y-hidden overflow-x-hidden">
                <img
                  src={isImagePreview?.url}
                  alt="Vehicle"
                  className="w-full max-h-[500px] object-cover rounded-xl"
                />
                <button
                  onClick={() => {
                    setFile(null);
                    setIsImagePreview({
                      isPreview: false,
                      url: "",
                    });
                  }}
                  className="absolute top-2 right-2 bg-white rounded-full"
                >
                  <MdCancel className="w-5 h-5 text-ezRed" />
                </button>
              </div>
            ) : (
              <div className="w-full h-full">
                <FileUploader
                  handleChange={handleImagePreview}
                  name="file"
                  maxSize={10}
                  label="Upload or drag & drop an image here"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
