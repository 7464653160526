import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { instanceSassChargeProd } from 'utls/instances';

const useSaasChargeProdAxiosInterceotor = () => {
  const [isSassLoading, setIsSassLoading] = useState(false);
  useEffect(() => {
    const requestIntercept = instanceSassChargeProd.interceptors.request.use(
      (config) => {
        setIsSassLoading(true);
        return config;
      },
      (error) => {
        toast.error(
          error?.response?.data?.error ||
            error?.response?.data?.errors?.[0] ||
            error.message
        );
        return error;
      }
    );

    const responseIntercept = instanceSassChargeProd.interceptors.response.use(
      function (response) {
        setIsSassLoading(false);
        return response;
      },
      function (error) {
        setIsSassLoading(false);
        toast.error(
          error?.response?.data?.error ||
            error?.response?.data?.errors?.[0] ||
            error.message
        );
        return error;
      }
    );

    return () => {
      instanceSassChargeProd.interceptors.request.eject(requestIntercept);
      instanceSassChargeProd.interceptors.response.eject(responseIntercept);
    };
  }, []);

  return { instanceSassChargeProd, isSassLoading };
};

export default useSaasChargeProdAxiosInterceotor;
