import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';

export default function VehicleCard({ vehicle, setDelVehicle, handleUpdate }) {
  return (
    <div className='bg-white p-4 md:pt-2 lg:p-4 min-w-full rounded-md shadow-md flex flex-col justify-between transition-all duration-300 ease-in-out border overflow-visible h-full md:h-72 lg:h-[19rem] w-full'>
      <div className='flex flex-col items-center justify-between h-full w-full'>
        <img
          src={
            vehicle?.picture ? vehicle?.picture : '/assets/images/carImg2.png'
          }
          alt='Car Wheel'
          className='w-72 h-48 object-contain rounded-lg mt-2'
        />
        <div className='flex justify-between items-end w-full'>
          <div className='col-span-3 md:col-span-4 w-full block'>
            <h3 className='text-ezBlack text-md font-semibold mb-1 truncate max-w-[4rem]'>
              {vehicle?.make?.name}
            </h3>
            <div className='flex'>
              <span className='p-2 border border-ezLightGray rounded-md mr-2'>
                <p className='text-ezBlack text-sm mb-1 truncate max-w-[6rem]'>
                  {vehicle?.model?.model}
                </p>
              </span>
              <span className='p-2 border border-ezLightGray rounded-md'>
                <p className='text-ezBlack text-sm mb-1'>{vehicle?.range}</p>
              </span>
            </div>
          </div>
          <div className='flex justify-end'>
            <Menu as='div' className='relative inline-block text-left'>
              <div>
                <Menu.Button className='-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600 mb-0.5'>
                  <BsThreeDotsVertical className='h-5 w-5' aria-hidden='true' />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute right-6 -bottom-11 z-20 mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -translate-y-full'>
                  <div className='p-0'>
                    <button
                      onClick={() => handleUpdate(vehicle)}
                      type='button'
                      className='w-full text-left flex justify-left items-center gap-x-3 text-sm text-ezGreen p-2 hover:bg-ezLightGreen'
                    >
                      <FiEdit className='w-5 h-6' /> Edit
                    </button>
                    <button
                      type='button'
                      onClick={() =>
                        setDelVehicle({ isDelVehicle: true, vehicle })
                      }
                      className='w-full text-left flex justify-left items-center gap-x-3 text-sm text-ezRed p-2 hover:bg-ezLightRed '
                    >
                      <MdDeleteOutline className='w-6 h-6' /> Delete
                    </button>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
