import NotificationHandler from 'components/notification/notificationHandler';
import useApiHook from 'hooks/useApiHook';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleAds } from 'redux/dashbbaord/dashboardSlice';
import useAuthRoutes from 'routes/useAuthRoutes';

function App() {
  const location = useLocation();
  const routes = useAuthRoutes();
  const { handleApiCall } = useApiHook();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [ads, setAds] = useState([]);

  const getAllAds = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `/ads`,
    });
    if (result?.status === 200) {
      setAds(result?.data?.ads);
    }
  };

  useEffect(() => {
    // Fetch ads once on component mount
    getAllAds();
  }, [auth?.isLogin]);

  useEffect(() => {
    if (ads?.length > 0) {
      // Switch to the next ad every 5 seconds
      const intervalId = setInterval(() => {
        dispatch(handleAds([ads[currentAdIndex]])); // Dispatch the current ad to the Redux store
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length); // Switch to the next ad
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [ads, currentAdIndex, dispatch]); // Re-run the effect when ads or currentAdIndex change

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <div className='ezAppLayout'>
      {routes}
      <NotificationHandler />
    </div>
  );
}

export default App;
