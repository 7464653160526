import { addNewSvg, changeSvg, editSvg } from 'helper/helper';

const PlanVehicleCard = ({
  vehicle,
  setIsVehicleChange,
  setIsVehicleModal,
  setIsVehicleUpdate,
  userVehicles,
}) => {
  return (
    <div className='px-4 pt-4 bg-ezLightWhite w-full mt-4 rounded-lg'>
      <div className='ez__info'>
        <h3 className='text-ezDarkGray text-lg font-bold'>
          {vehicle?.model?.model}
        </h3>
        <p className='text-black text-sm block w-full mb-1 font-semibold'>
          <span className='mr-2 font-normal'> Make:</span>
          {vehicle?.make?.name}
        </p>
        <p className='text-black text-sm block w-full mb-1 font-semibold'>
          <span className='mr-2 font-normal'> Range:</span>
          {vehicle?.range}
        </p>
      </div>
      <div className='flex flex-row justify-center mx-2'>
        <img
          src={
            vehicle?.picture ? vehicle?.picture : '/assets/images/carImg2.png'
          }
          alt='Car Wheel'
          className='w-72 h-60 object-contain rounded-lg col-span-2 py-2'
        />
      </div>
      <div className='flex flex-row justify-end gap-x-1 pb-4 pt-2'>
        <button
          type='button'
          onClick={() => setIsVehicleModal(true)}
          className='text-ezGray text-left hover:text-white hover:bg-ezLightGreen text-xs rounded-full'
        >
          {addNewSvg}
        </button>
        <button
          type='button'
          onClick={() => setIsVehicleUpdate(true)}
          className='text-ezGray text-left hover:text-white hover:bg-ezLightGreen text-xs rounded-full'
        >
          {editSvg}
        </button>
        {/* {userVehicles?.length > 1 && (
          <button
            type='button'
            onClick={() => setIsVehicleChange(true)}
            className='text-ezGray text-left hover:text-white hover:bg-ezLightGreen text-xs rounded-full'
          >
            {changeSvg}
          </button>
        )} */}
      </div>
    </div>
  );
};

export default PlanVehicleCard;
