import { useCountHook } from 'hooks/useCountHook';
import { AiOutlineClockCircle } from 'react-icons/ai';

const UpComingTimer = ({ startTime }) => {
  const [days, hours, minutes, seconds] = useCountHook(startTime);

  return (
    <p className='flex items-center justify-center w-full mt-2 text-ezBlack text-3xl bg-red-200 py-1 px-6 rounded-lg'>
      <AiOutlineClockCircle className='w-5 h-5 mr-2 text-red-700' />{' '}
      {hours < 0 || minutes < 0 || seconds < 0 ? (
        <div className='flex items-center justify-start text-base text-red-700'>
          <span className='rounded-sm p-2 mx-1'>0</span>:
          <span className='rounded-sm p-2 mx-1'>0</span>:
          <span className='rounded-sm p-2 mx-1'>0</span>
        </div>
      ) : (
        <div className='flex items-center justify-start text-base text-red-700'>
          <span className='rounded-sm p-2 mx-1'>{hours} h</span>
          <span className='rounded-sm p-2 mx-1'>{minutes} m</span>
          <span className='rounded-sm p-2 mx-1'>{seconds} s</span>
        </div>
      )}
    </p>
  );
};

export default UpComingTimer;
