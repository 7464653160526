import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { handlPlanTripModal } from 'redux/dashbbaord/dashboardSlice';
import { tick } from 'helper/helper';
import { MdCancel } from 'react-icons/md';
import { useJsApiLoader } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import UpdateTripStep3 from 'components/planTrip/updateTrip/UpdateTripStep3';
import { initialValues } from 'helper/functionality';
import useApiHook from 'hooks/useApiHook';
import { toast } from 'react-toastify';
import UpdateTripStep1And2 from 'components/planTrip/updateTrip/UpdateTripStep1And2';

export default function UpdatePlanTrip({
  isTripUpdate,
  setIsTripUpdate,
  tripDetail,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiCall, isApiLoading } = useApiHook();
  const { auth } = useSelector((state) => state);
  const cancelButtonRef = useRef(null);
  const [steps, setSteps] = useState(1);
  const [previousStep, setPreviousStep] = useState(0);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [formValues, setFormValues] = useState({
    startFrom: tripDetail?.origin?.text,
    destination: tripDetail?.destination?.text,
    inBetween: tripDetail?.stops?.map((stop, ind) => {
      return {
        id: `${new Date().toISOString()}${ind}`,
        value: stop?.name,
        type: stop?.type,
      };
    }),
    startDate: tripDetail?.startDate ? new Date(tripDetail?.startDate) : null,
    startTime: tripDetail?.startTime ? new Date(tripDetail?.startTime) : null,
    avoidTolls: tripDetail?.avoidTolls,
    avoidTraffic: tripDetail?.avoidTraffic,
    avoidHighways: tripDetail?.avoidHighways,
    compatible: tripDetail?.compatible || true,
    hotels: tripDetail?.hotels,
    restaurants: tripDetail?.restaurants,
    campGround: tripDetail?.campGround,
    vehicle: {
      ...tripDetail?.vehicleId,
      make: {
        _id: tripDetail?.vehicleId?.make?._id,
        name: tripDetail?.vehicleId?.make?.make,
      },
      model: {
        ...tripDetail?.vehicleId?.make?.models[0],
      },
    },
    chargersType: tripDetail?.chargersType,
    connectorType: tripDetail?.connector,
    network: tripDetail?.network,
    distance: tripDetail?.distance,
    time: tripDetail?.time,
    speed: tripDetail?.speed,
    energy: tripDetail?.energy,
    cost: tripDetail?.cost,
  });
  const [tripDirections, setTripDirections] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });
  const [directions, setDirections] = useState(null);

  const updateTrip = async () => {
    let startTime = null;
    if (formValues?.startDate && formValues.startTime) {
      let setStartTime = new Date(formValues.startTime);
      setStartTime.setMonth(new Date(formValues.startDate)?.getMonth());
      setStartTime.setFullYear(new Date(formValues.startDate)?.getFullYear());
      setStartTime.setDate(new Date(formValues.startDate)?.getDate());
      startTime = new Date(setStartTime);
    }
    let data = {
      origin: {
        text: formValues.startFrom,
        latitude: tripDirections?.routes[0]?.legs[0]?.start_location?.lat(),
        longitude: tripDirections?.routes[0]?.legs[0]?.start_location?.lng(),
      },
      destination: {
        text: formValues.destination,
        latitude:
          tripDirections?.routes[0]?.legs[
            tripDirections?.routes[0]?.legs?.length - 1
          ]?.end_location?.lat(),
        longitude:
          tripDirections?.routes[0]?.legs[
            tripDirections?.routes[0]?.legs?.length - 1
          ]?.end_location?.lng(),
      },
      stops: formValues.inBetween?.map((stop) => ({
        name: stop?.value,
        type: stop?.type,
      })),
      startDate: formValues.startDate
        ? formValues.startDate >= new Date()
          ? formValues.startDate
          : new Date()
        : null,
      startTime: startTime
        ? startTime >= new Date()
          ? startTime
          : new Date()
        : null,
      chargersType: formValues.chargersType,
      connector: formValues.connectorType,
      network: formValues.network,
      distance: formValues.distance,
      time: formValues.time,
      speed: formValues.speed,
      energy: formValues.energy,
      cost: formValues.cost,
      vehicleId: formValues.vehicle?._id,
      avoidTolls: formValues.avoidTolls,
      avoidTraffic: formValues.avoidTraffic,
      avoidHighways: formValues.avoidHighways,
      hotels: formValues.hotels,
      restaurants: formValues.restaurants,
      campGround: formValues.campGround,
    };
    const result = await handleApiCall({
      method: 'patch',
      url: `/trip/${auth?.userInfo?.user?._id}/${tripDetail?._id}`,
      data,
    });
    if (result?.status === 200) {
      toast.success('Your trip has been updated.');
      setFormValues(initialValues);
      setStepCompleted(true);
      handlDone();
    }
  };

  const handlDone = () => {
    setIsTripUpdate(false);
    setSteps(1);
    setStepCompleted(false);
    navigate('/my-trips');
  };

  return (
    <Transition.Root show={isTripUpdate || false} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 flex items-center justify-center'
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(handlPlanTripModal(false))}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='relative z-10 max-w-4xl w-full'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <Dialog.Panel className='relative transform text-left bg-tansparent transition-all sm:my-8 sm:max-w-4xl w-full'>
              {!stepCompleted ? (
                <div className='px-8 pt-8 pb-6 bg-white shadow-xl w-full h-full rounded-xl'>
                  <div className='ez__PlanTripHead w-full flex items-center justify-between'>
                    <div className='flex flex-col'>
                      <div className='text-lg font-bold my-1'>
                        Update a Trip
                      </div>
                    </div>
                    <button
                      type='button'
                      onClick={() => {
                        setIsTripUpdate(false);
                        setStepCompleted(false);
                        setFormValues(initialValues);
                        setSteps(1);
                      }}
                      className='bg-white text-ezDarkGray rounded-full block w-max mb-auto'
                    >
                      <MdCancel className='w-8 h-8' />
                    </button>
                  </div>
                  <hr className='border-gray-300 my-3' />

                  {+steps === 1 ? (
                    <UpdateTripStep1And2
                      userInfo={auth?.userInfo}
                      setSteps={setSteps}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      directions={directions}
                      setDirections={setDirections}
                      setTripDirections={setTripDirections}
                      setPreviousStep={setPreviousStep}
                    />
                  ) : +steps === 2 ? (
                    <UpdateTripStep3
                      setSteps={setSteps}
                      formValues={formValues}
                      setTripDirections={setTripDirections}
                      directions={directions}
                      setFormValues={setFormValues}
                      tripDirections={tripDirections}
                      tripLoading={isApiLoading}
                      updateTrip={updateTrip}
                      previousStep={previousStep}
                    />
                  ) : null}
                </div>
              ) : (
                <div className='block w-full p-6' style={{ height: '600px' }}>
                  <div className='border border-ezGreen rounded-md flex flex-col items-center justify-center w-full h-full relative'>
                    <button
                      type='button'
                      onClick={() => {
                        dispatch(handlPlanTripModal(false));
                        setSteps(1);
                        setStepCompleted(false);
                      }}
                      className='bg-white text-ezGreen absolute -top-3 -right-3 z-10'
                    >
                      <MdCancel className='w-8 h-8' />
                    </button>
                    <div className='ez__Svg text-ezGreen mb-5'>{tick}</div>
                    <h3 className='text-ezBlack font-bold text-lg md:text-2xl mb-3'>
                      Congratulations!
                    </h3>
                    <h3 className='text-ezBlack font-bold text-lg md:text-2xl mb-4'>
                      Your trip has been updated successfully
                    </h3>
                    <button
                      type='button'
                      onClick={handlDone}
                      className='w-max px-10 bg-ezGreen hover:bg-transparent hover:text-ezGreen border-ezGreen cursor-pointer text-white rounded-md py-3 text-center block border'
                    >
                      Done
                    </button>
                  </div>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
