import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { mapGear, radiusData } from 'helper/helper';
import RadioBtn from 'components/btns/RadioBtn';

export default function RadiusCard({
  filterRadius,
  setFilterRadius,
  applyRadiusFilters,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div
        className={`w-8 h-8 md:w-16 md:h-12 z-10 flex justify-center items-center md:rounded-none rounded-full ${
          isOpen
            ? 'bg-ezGreen hover:cursor-default'
            : 'bg-white hover:bg-ezLightGreen'
        }`}
      >
        <Menu.Button
          className='w-8 md:w-12 h-8 md:h-10 right-14 z-10 flex justify-center items-center'
          onClick={() => setIsOpen(!isOpen)}
        >
          {mapGear(isOpen ? 'white' : 'black')}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
        beforeLeave={() => setIsOpen(false)}
      >
        <Menu.Items
          className='absolute bottom-full mb-1 right-8 md:right-0 z-10 w-[250px] md:w-[450px] origin-top-right px-1 md:px-0 rounded-xl bg-white shadow-lg focus:outline-none'
          static
        >
          <div className='block w-full'>
            <div className='ez__Title p-4 text-black rounded-t-md'>
              <p className='text-lg font-bold text-black'>
                Choose search radius
              </p>
            </div>
            <hr className='border-gray-300 mx-4 mb-1' />
            <div className='ez__Body flex flex-row justify-around py-1 mx-1 flex-wrap md:flex-nowrap'>
              {radiusData?.map((radius) => (
                <RadioBtn
                  id={radius?.id}
                  key={radius?.id}
                  text={radius?.text}
                  isSelected={radius?.value === filterRadius}
                  onClick={() => {
                    setFilterRadius(radius?.value);
                    applyRadiusFilters(radius?.value);
                    setIsOpen(false);
                  }}
                />
              ))}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
