import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebar: false,
  isHomePage: true,
  isPlanPage: false,
  isTripsPage: false,
  isVehiclesPage: false,
  isPlanModal: false,
  isPlanTripModal: false,
  isNoTripModal: false,
  isNoVehicleModal: false,
  isNoChargingModal: false,
  isPlanApiCall: false,
  isVehicleApiCall: false,
  vehicleManufactures: [],
  reviews: [],
  ads: [],
  notifications: [],
  notReadNotificationCount: 0,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    handlPlanTripModal: (state, { payload }) => {
      state.isPlanModal = payload;
    },
    handlSidebar: (state, { payload }) => {
      state.isSidebar = payload;
    },
    toggleHomePage: (state, { payload }) => {
      state.isHomePage = payload;
      state.isTripsPage = false;
      state.isVehiclesPage = false;
      state.isPlanPage = false;
    },
    toggleTripsPage: (state, { payload }) => {
      state.isHomePage = false;
      state.isTripsPage = payload;
      state.isVehiclesPage = false;
      state.isPlanPage = false;
    },
    toggleVehiclesPage: (state, { payload }) => {
      state.isHomePage = false;
      state.isTripsPage = false;
      state.isPlanPage = false;
      state.isVehiclesPage = payload;
    },
    togglePlanTripPage: (state, { payload }) => {
      state.isHomePage = false;
      state.isTripsPage = false;
      state.isVehiclesPage = false;
      state.isPlanPage = payload;
    },
    toggleClearPages: (state) => {
      state.isHomePage = false;
      state.isTripsPage = false;
      state.isVehiclesPage = false;
      state.isPlanPage = false;
    },
    toogleVehicleManufactures: (state, { payload }) => {
      state.vehicleManufactures = payload;
    },
    toggleIsPlanApiCall: (state, { payload }) => {
      state.isPlanApiCall = payload;
    },
    toggleIsVehicleApiCall: (state, { payload }) => {
      state.isVehicleApiCall = payload;
    },
    addNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    setNotReadNotificationCount: (state, { payload }) => {
      state.notReadNotificationCount = payload;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    handleReviews: (state, { payload }) => {
      state.reviews = payload;
    },
    handleAds: (state, { payload }) => {
      state.ads = payload;
    },
    addNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  handlePlanTripStepsModal,
  handlPlanTripModal,
  togglePlanTripPage,
  handlSidebar,
  toggleHomePage,
  toggleTripsPage,
  toggleVehiclesPage,
  toggleClearPages,
  toogleVehicleManufactures,
  toggleIsPlanApiCall,
  toggleIsVehicleApiCall,
  handleReviews,
  handleAds,
  addNotifications,
  clearNotifications,
  setNotReadNotificationCount
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
