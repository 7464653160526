import PhoneVerification from 'components/myProfile/PhoneVerification';
import ProfileForm from 'components/myProfile/ProfileForm';
import ProfilePasswordForm from 'components/myProfile/ProfilePasswordForm';
import Spiner from 'helper/Spiner';
import useApiHook from 'hooks/useApiHook';
import DeleteAccount from 'modals/deleteAccount/DeleteAccount';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { BsCameraFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleLogin, updateUser } from 'redux/auth/authSlice';
import { toggleClearPages } from 'redux/dashbbaord/dashboardSlice';

export default function Profile() {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isApiLoading, handleApiCall } = useApiHook();
  const [isModal, setIsModal] = useState(false);
  const [isRegisterConsent, setIsRegisterConsent] = useState(
    auth?.userInfo?.user?.isRegisterConsent
  );
  const [currentTab, setCurrentTab] = useState('personalInfo');
  const [isLoading, setIsLoading] = useState(false);

  const deleteUserAccount = async () => {
    setIsLoading(true);
    const result = await handleApiCall({
      method: 'delete',
      url: `user/profile/delete/${auth?.userInfo?.user?._id}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (result?.status === 200) {
      setIsModal(false);
      dispatch(toggleLogin({ isLogin: false, userInfo: null }));
      toast.success(result?.data?.message);
      setTimeout(() => {
        navigate('/login');
      }, 100);
    }
    setIsLoading(false);
  };

  const updateProfileImage = async (image) => {
    setIsLoading(true);
    if (!image)
      return toast.error('Please select image to update the profile.');
    const formData = new FormData();
    formData.append('image', image);
    const result = await handleApiCall({
      method: 'post',
      url: `user/profile-image/${auth?.userInfo?.user?._id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (result?.status === 200) {
      toast.success('Profile image uploaded successfully');
      getUserInfo();
    }
    setIsLoading(false);
  };

  const getUserInfo = async () => {
    setIsLoading(true);
    const result = await handleApiCall({
      method: 'get',
      url: 'user/profile',
    });
    if (result?.status === 200) {
      dispatch(updateUser(result.data?.user));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const updateRegisterConsent = async () => {
      if (isRegisterConsent !== auth?.userInfo?.user?.isRegisterConsent) {
        const result = await handleApiCall({
          method: 'patch',
          url: `user/profile/consent/${auth?.userInfo?.user?._id}`,
          data: {
            isRegisterConsent,
          },
        });
        if (result?.status === 200) {
          toast.success(result?.data?.message);
          getUserInfo();
        }
      }
    };

    updateRegisterConsent();

    return () => {};
  }, [isRegisterConsent]);

  useEffect(() => {
    dispatch(toggleClearPages());
  }, []);

  return (
    <div className='ez__Profile w-full relative'>
      {isApiLoading && isLoading && (
        <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center transparency z-50'>
          <Spiner color='ezGreen' />
        </div>
      )}

      <div className='w-full p-10 md:flex gap-4'>
        <div className='w-[16rem] text-black mb-4 md:mb-0 '>
          <button
            onClick={() => {
              setCurrentTab('personalInfo');
            }}
            className={`text-base h-10 w-full flex flex-col  justify-center pl-2  ${
              currentTab === 'personalInfo'
                ? 'font-medium bg-white border-l-2 border-l-ezGreen'
                : 'font-normal'
            }`}
          >
            Personal Information
          </button>
          <button
            onClick={() => {
              setCurrentTab('changePassword');
            }}
            className={`text-base h-10 w-full flex flex-col  justify-center pl-2  ${
              currentTab === 'changePassword'
                ? 'font-medium bg-white border-l-2 border-l-ezGreen'
                : 'font-normal'
            }`}
          >
            Change Password
          </button>
          <button
            onClick={() => {
              setCurrentTab('deleteAccount');
            }}
            className={`text-base h-10 w-full flex flex-col  justify-center pl-2  ${
              currentTab === 'deleteAccount'
                ? 'font-medium text-red-500 bg-white border-l-2 border-l-red-500'
                : 'font-normal text-red-500'
            }`}
          >
            Delete Account
          </button>
        </div>
        {currentTab === 'personalInfo' && (
          <div className='w-full flex flex-col lg:flex-row gap-2'>
            <div className='w-full lg:w-2/4 bg-white p-6 rounded-md '>
              <h3 className='mb-2 text-black text-lg'>Profile Details</h3>
              <div className='col-span-full md:flex grid grid-cols-6 items-center md:gap-x-4 mb-5'>
                <div className='block w-16 col-span-2 rounded-full relative group'>
                  {auth?.userInfo?.user?.profileImage ? (
                    <img
                      src={auth?.userInfo?.user?.profileImage}
                      alt='Profile Image'
                      className='h-16 w-auto flex-none rounded-full object-cover'
                    />
                  ) : (
                    <div className='h-16 w-auto block'>
                      <img
                        className='rounded-full border border-ezGreen block'
                        src={'/assets/images/avatar.png'}
                        alt='Guest Profile'
                      />
                    </div>
                  )}
                  <label className='hidden group-hover:flex absolute top-0 left-0 w-16 h-full rounded-full items-center justify-center bg-black bg-opacity-40'>
                    <BsCameraFill className='w-8 h-8 text-white' />
                    <input
                      type='file'
                      onChange={(e) => updateProfileImage(e.target.files[0])}
                      id='image'
                      name='image'
                      accept='image/*'
                      className='absolute w-full h-full opacity-0 top-0 right-0 cursor-pointer'
                    />
                  </label>
                </div>

                <div className='block col-span-4 truncate ...'>
                  <p className='text-sm md:text-xl leading-5 text-ezBlack'>
                    {auth?.userInfo?.user?.name}
                  </p>
                  <p className='text-sm leading-5 text-ezBlack'>
                    {auth?.userInfo?.user?.email}
                  </p>
                </div>
              </div>

              <ProfileForm
                getUserInfo={getUserInfo}
                userInfo={auth?.userInfo}
                setIsLoading={setIsLoading}
              />
            </div>
            <div className='w-full lg:w-2/4 bg-white p-6 md:h-[13rem] rounded-md'>
              <h3 className='mb-2 text-black text-lg'>
                Phone Number Verification{' '}
              </h3>

              <PhoneVerification
                getUserInfo={getUserInfo}
                userInfo={auth?.userInfo}
              />
            </div>
          </div>
        )}

        {currentTab === 'changePassword' && (
          <div className='w-full'>
            <div className='w-full lg:w-2/4 bg-white p-6 rounded-md'>
              <h3 className='mb-2 text-black text-lg'>Change Password </h3>
              <ProfilePasswordForm
                getUserInfo={getUserInfo}
                userInfo={auth?.userInfo}
              />
            </div>
          </div>
        )}

        {currentTab === 'deleteAccount' && (
          <div className='w-full'>
            <div className='w-full lg:w-2/4 bg-white p-6 rounded-md'>
              <h3 className='mb-2 text-red-500 text-lg'>Delete Account</h3>

              <p className='text-base text-ezGray w-auto'>
                No longer want to use our service? You can delete your account
                here. This action is not reversible. All information related to
                this account will be deleted permanently.
              </p>

              <div className='w-full flex justify-center mt-5'>
                <button
                  type='button'
                  onClick={() => setIsModal(!isModal)}
                  className='rounded-md w-full xl:w-1/2 bg-ezRed border-ezRed border px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-transparent hover:text-ezRed'
                >
                  Yes, delete my account
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <div className="grid max-w-7xl grid-cols-1 gap-x-14 gap-y-10 px-4 py-5 md:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-ezGray">
              Email Newsletter
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-400">
              We'll occasionally send you emails with helpful information,
              updates, and exclusive offers. If you prefer not to receive these
              emails, you can easily opt in or opt out by checking or unchecking
              the box respectively.
            </p>
          </div>

          <div className="md:col-span-2">
            <div className="col-span-full flex items-center gap-x-4 mb-5 md:w-2/3">
              <input
                type="checkbox"
                className="text-ezGreen font-semibold hover:text-ezGreen mt-0.5 w-4 h-4"
                defaultChecked={isRegisterConsent}
                onChange={(e) => {
                  setIsRegisterConsent(e.target.checked);
                }}
              />
              <p className="mt-1 text-sm text-ezBlack leading-6">
                Receive email updates.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      {isModal && (
        <DeleteAccount
          isApiLoading={isApiLoading}
          isModal={isModal}
          setIsModal={setIsModal}
          deleteUserAccount={deleteUserAccount}
        />
      )}
    </div>
  );
}
