import { ErrorMessage, Field, Form, Formik } from 'formik';
import Spiner from 'helper/Spiner';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import useApiHook from 'hooks/useApiHook';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import PrimaryBtn from 'components/btns/PrimaryBtn';

const SetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 character are required!')
    .max(16, 'Maximum 16 character are required!')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,16}$/,
      'Password must contain at least one number, one uppercase letter, one lowercase letter and one special character!'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf(
      [Yup.ref('password')],
      'Confirm password need to be the same as password'
    ),
});

const SetNewPassword = () => {
  const { auth } = useSelector((state) => state);
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isTypePasssword, setIsTypePasssword] = useState(true);
  const [isTypeConfirmPasssword, setIsTypeConfirmPasssword] = useState(true);

  const handleSetPassword = async (values) => {
    if (!token) return toast.error('Verification token is not valid');
    const result = await handleApiCall({
      method: 'post',
      url: `/set-password/${token}`,
      data: { password: values?.password },
    });
    if (result?.status === 200) {
      toast.success(result?.data?.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
  };

  useEffect(() => {
    if (auth?.isLogin) navigate('/home');
  }, [auth?.isLogin]);

  return (
    <div className='w-full min-h-screen bg-ezMidWhite'>
      <div className='flex flex-col   lg:grid  lg:grid-cols-2 px-4 lg:px-0 items-center w-full h-full'>
        <div className='h-auto w-full items-center py-10 flex flex-col justify-center lg:hidden'>
          <img
            className='h-10 sm:h-14 w-auto'
            src='/assets/images/ezvoltzLogo.png'
            alt='Your Company'
          />
        </div>
        <div className='ez__AuthForm  lg:px-28  md:px-10  xl:px-48'>
          <div className='title w-full flex  justify-center flex-col'>
            <h3 className='text-2xl font-semibold mb-4 text-ezBlack'>
              Recover your account
            </h3>
            <p className='text-base mb-8 text-ezBlack'>
              Enter the fields below to get started
            </p>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={SetPasswordSchema}
              onSubmit={handleSetPassword}
            >
              {({}) => (
                <Form className='ez__Form w-full'>
                  <span className='text-sm text-ezNuturalGray'>PASSWORD</span>
                  <div className='relative w-full'>
                    <Field
                      type={isTypePasssword ? 'password' : 'text'}
                      name='password'
                      placeholder='Password'
                      className='w-full block border border-ezMidGray rounded-lg  text-base text-ezBlack p-2 mb-4'
                    />
                    <button
                      type='button'
                      onClick={() => setIsTypePasssword(!isTypePasssword)}
                      className='absolute top-3 right-4 z-50 w-max text-ezBlack hover:text-ezGreen'
                    >
                      {isTypePasssword ? (
                        <BsFillEyeSlashFill
                          className='w-5 h-5'
                          color='#D3D3D3'
                        />
                      ) : (
                        <BsFillEyeFill className='w-5 h-5 ' color='#D3D3D3' />
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    name='password'
                    render={(msg) => (
                      <p className='text-sm text-ezRed block mb-4 -mt-3'>
                        {msg}
                      </p>
                    )}
                  />
                  <span className='text-sm text-ezNuturalGray'>
                    CONFIRM PASSWORD
                  </span>
                  <div className='relative w-full'>
                    <Field
                      type={isTypeConfirmPasssword ? 'password' : 'text'}
                      name='confirmPassword'
                      placeholder='Confirm Password'
                      className='w-full block border border-ezMidGray rounded-lg  text-base text-ezBlack p-2 mb-4'
                    />
                    <button
                      type='button'
                      onClick={() =>
                        setIsTypeConfirmPasssword(!isTypeConfirmPasssword)
                      }
                      className='absolute top-3 right-4 z-50 w-max text-ezBlack hover:text-ezGreen'
                    >
                      {isTypeConfirmPasssword ? (
                        <BsFillEyeSlashFill
                          className='w-5 h-5'
                          color='#D3D3D3'
                        />
                      ) : (
                        <BsFillEyeFill className='w-5 h-5 ' color='#D3D3D3' />
                      )}
                    </button>
                  </div>
                  <ErrorMessage
                    name='confirmPassword'
                    render={(msg) => (
                      <p className='text-sm text-ezRed block mb-4 -mt-3'>
                        {msg}
                      </p>
                    )}
                  />

                  <PrimaryBtn
                    btnType='submit'
                    isApiLoading={isApiLoading}
                    text='Set New Password'
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className='h-screen w-full hidden lg:block'>
          <img
            src='/assets/images/login.png'
            alt='Login'
            className='w-full h-full'
          />
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
