import AppleLogin from 'react-apple-login';

const AppleLoginButton = ({ appleLoginSuccess }) => {
  return (
    <div className='w-full block'>
      <AppleLogin
        clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
        redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
        usePopup={true}
        callback={appleLoginSuccess}
        scope='email name'
        responseMode='query'
        render={({ onClick }) => (
          <button
            className='py-2.5 border border-ezMidGray  bg-white  text-sm text-black rounded-md w-full flex items-center justify-center hover:text-ezGreen  hover:border-ezGreen'
            onClick={onClick}
          >
            <img
              src='/assets/svgs/apple.svg'
              alt='facebook'
              className='h-5 w-auto mr-2'
            />
          </button>
        )}
      />
    </div>
  );
};

export default AppleLoginButton;
