import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "redux/auth/authSlice";
import Autocomplete from "react-google-autocomplete";
import { AiOutlineSearch } from "react-icons/ai";
import { MdCancel, MdOutlineCancel } from "react-icons/md";

const HeaderPlacesInput = () => {
  const placeRef = useRef();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    placeRef.current.value = auth?.userLocation?.name || "";
    if (auth?.userLocation?.name !== "") setShowCancel(true);
    else setShowCancel(false);
  }, [auth?.userLocation]);

  const clearInput = () => {
    placeRef.current.value = "";
    setShowCancel(false);
  };

  return (
    <div className="ez__HeaderSearch w-full rounded-lg md:flex hidden items-center bg-white py-3 px-10 md:px-10 lg:px-4  shadow-md">
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        className="w-full block"
        ref={placeRef}
        onPlaceSelected={(place) => {
          let latitude = place.geometry.location.lat();
          let longitude = place.geometry.location.lng();
          setShowCancel(true);
          dispatch(
            updateLocation({
              name: place?.formatted_address,
              placeId: place?.place_id,
              state: place?.address_components[2]?.short_name,
              latitude,
              longitude,
            })
          );
        }}
      />
      <button
        type="button"
        onClick={clearInput}
        className="bg-transparent  border-0 focus:outline-none pl-2"
      >
        {showCancel ? (
          <MdCancel className="w-6 h-auto text-ezGray" onClick={clearInput} />
        ) : (
          <AiOutlineSearch className="w-5 h-auto text-ezGray" />
        )}
      </button>
    </div>
  );
};

export default HeaderPlacesInput;
