import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FiAlertTriangle } from 'react-icons/fi';
import Loader from 'helper/Loader';
import useApiHook from 'hooks/useApiHook';
import { toast } from 'react-toastify';
import { BsTrashFill } from 'react-icons/bs';

export default function DeleteVehicle({
  userInfo,
  delVehicle,
  setDelVehicle,
  vehicles,
  setVehicles,
}) {
  const cancelButtonRef = useRef(null);
  const { handleApiCall, isApiLoading } = useApiHook();

  const deleteVehicle = async () => {
    const result = await handleApiCall({
      method: 'delete',
      url: `/vehicle/${userInfo?.user?._id}/${delVehicle?.vehicle?._id}`,
    });
    if (result?.status === 200) {
      toast.success(
        'Vehicle and trips related to that vehicle deleted successfully.'
      );
      setVehicles(
        vehicles?.filter((vehicle) => vehicle?._id !== delVehicle?.vehicle?._id)
      );
      setDelVehicle({ isDelVehicle: false, vehicle: null });
    }
  };

  return (
    <Transition.Root show={delVehicle?.isDelVehicle || false} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => setDelVehicle({ isDelVehicle: true, vehicle: null })}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0'>
            {isApiLoading && <Loader background='transparency' />}
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='w-full relative transform overflow-hidden bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm p-5 rounded-md'>
                <div className='sm:flex sm:flex-col sm:items-center sm:justify-center sm:text-center'>
                  <div className='mx-auto mb-5 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-600 sm:mx-0 sm:h-16 sm:w-16 drop-shadow-lg'>
                    <BsTrashFill
                      className='h-9 w-9 text-white'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Delete Vehicle Confirmation
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Are you sure you want to delete your vehicle? All trips
                        associated with this vehicle will be permanently
                        deleted.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:grid sm:grid-cols-2 gap-x-3'>
                  <button
                    type='button'
                    className='mt-3  inline-flex w-full justify-center rounded-md border border-ezLightGray bg-white px-4 py-2 text-base font-medium text-ezGray shadow-sm hover:bg-gray-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() =>
                      setDelVehicle({ isDelVehicle: false, vehicle: null })
                    }
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='mt-3 md:mt-0 inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:w-auto sm:text-sm'
                    onClick={deleteVehicle}
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
