import { initializeApp } from 'firebase/app';
import 'firebase/messaging';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCa_KT09zpOOBp_yo4k-sig0QlRDAMYnGk',
  authDomain: 'ezvoltz-fcd40.firebaseapp.com',
  projectId: 'ezvoltz-fcd40',
  storageBucket: 'ezvoltz-fcd40.appspot.com',
  messagingSenderId: '933471212017',
  appId: '1:933471212017:web:06738ed53f2d61c3a43450',
  measurementId: 'G-FPJS9S2M8P',
};
const app = initializeApp(firebaseConfig);

export const firebaseMessaging = getMessaging(app);
