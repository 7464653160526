import { GoogleMap, DirectionsRenderer, Circle } from '@react-google-maps/api';
import {
  clockSvg,
  dollorSvg,
  energySvg,
  milesSvg,
  speedSvg,
} from 'helper/helper';
import { useEffect, useState } from 'react';
import Spiner from 'helper/Spiner';
import { toast } from 'react-toastify';
import { BsInfoLg } from 'react-icons/bs';
import TripEVMarker from 'components/home/TripEVMarker';
import { instanceNREL } from 'utls/instances';
import GrayBtn from 'components/btns/GrayBtn';
import OutlineBtn from 'components/btns/OutlineBtn';
import useHaloRange from 'hooks/useHaloRange';

const PlanTripSteps3 = ({
  formValues,
  setFormValues,
  setSteps,
  directions,
  addTrip,
  tripLoading,
  creatingTrip,
}) => {
  const { halos, calculateHaloRange, loading } = useHaloRange();
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [isLoading, setIsLoading] = useState(false);
  const [allEVs, setAllEVs] = useState(null);

  const getAllEV = async ({ lineString, retryTime = 0 }) => {
    if (retryTime > 1) return;
    setIsLoading(true);
    try {
      let url = `alt-fuel-stations/v1/nearby-route.json?route=LINESTRING(${lineString})&ev_network=${
        formValues?.network
      }&ev_connector_type=${formValues?.connectorType}&status=${
        formValues?.chargersType === 'availableChargers' ? 'E' : 'all'
      }&distance=1`;
      const result = await instanceNREL.get(url);
      if (result.status === 200) {
        setAllEVs(result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.code === 'ERR_NETWORK') {
        let lineString = directions.routes[0].overview_path
          ?.map((point, index) => {
            if (index === directions.routes[0].overview_path?.length - 1)
              return `${point.lng()} ${point.lat()}`;
            else if (index % 2 === 0) return `${point.lng()} ${point.lat()}, `;
          })
          .join('');
        return getAllEV({ lineString, retryTime: retryTime + 1 });
      }
      toast.error('No pins found against this route.');
    }
  };

  useEffect(() => {
    let lineString = directions.routes[0].overview_path
      ?.map((point) => {
        return `${point.lng()} ${point.lat()}`;
      })
      ?.join(', ');
    getAllEV({ lineString, retryTime: 0 });
    calculateHaloRange(
      directions.routes[0],
      formValues?.vehicle?.range || formValues?.vehicle?.model?.range
    );
  }, []);

  return (
    <div className='bg-white w-full'>
      <div className='scroll-shadows pl-2 max-h-[28rem]  overflow-y-auto m-0  w-full h-full'>
        <div className='w-full mapDirectionHeight block mb-5 rounded-md shadow-lg overflow-hidden mapOuterDiv'>
          {isLoading ? (
            <div className='w-full h-full p-0 m-0 bg-ezLightGreen'>
              <Spiner color='ezGreen' />
            </div>
          ) : (
            <GoogleMap
              zoom={12}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              onLoad={(map) => setMap(map)}
              options={{ minZoom: 4, maxZoom: 18 }}
            >
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    polylineOptions: {
                      strokeOpacity: 1,
                      strokeColor: '#228B22',
                      strokeWeight: 5,
                    },
                  }}
                />
              )}
              {!loading &&
                halos?.map((circle, index) => (
                  <Circle
                    key={index}
                    radius={25000}
                    visible
                    options={{
                      strokeOpacity: 1,
                      strokeColor: '#228B22',
                      strokeWeight: 2,
                    }}
                    center={{ lat: circle.lat, lng: circle.lng }}
                  />
                ))}
              {allEVs?.fuel_stations?.length > 0 &&
                allEVs?.fuel_stations?.map((ev) => (
                  <TripEVMarker
                    key={`EVPointsKey${ev?.id}--${new Date().getTime()}`}
                    ev={ev}
                    isStop={true}
                    addStop={() =>
                      setFormValues({
                        ...formValues,
                        inBetween: [
                          ...formValues?.inBetween?.filter(
                            (between) =>
                              between.value !==
                              `${ev?.street_address} ${ev?.city} ${ev?.state} ${ev?.country}`
                          ),
                          {
                            id: new Date().toISOString(),
                            value: `${ev?.street_address} ${ev?.city} ${ev?.state} ${ev?.country}`,
                            type: 'waypoint',
                          },
                        ],
                      })
                    }
                  />
                ))}
            </GoogleMap>
          )}
        </div>
        <div className='w-full group relative  md:hidden pl-2.5 flex  justify-end'>
          <button className='text-white bg-ezGray mr-1  focus:outline-none font-medium flex items-center justify-center text-xs w-5 h-5 rounded-full'>
            <BsInfoLg />
          </button>
          <span className='absolute mb-1.5 w-72 bottom-full right-0 scale-0 transition-all rounded-lg bg-ezGreen p-2 text-sm text-white group-hover:scale-100'>
            These calculations are based on past user data. Factors such as
            weather, speed, traffic, and jurisdictional charges impact our
            estimates.
          </span>
        </div>
        <div className='md:flex flex-wrap grid grid-cols-2 gap-y-2 gap-x-14 md:gap-x-2 relative items-center justify-between border-t py-3 border-ezLightGray mt-3 mb-2 mx-2'>
          <p className='flex items-center text-sm text-ezBlack mb-2 sm:mb-0'>
            <span className='text-ezGreen block mr-1'>{milesSvg}</span>{' '}
            {directions && formValues?.distance}
          </p>
          <p className='flex items-center text-sm text-ezBlack mb-2 sm:mb-0'>
            <span className='text-ezGreen block mr-1'>{clockSvg}</span>{' '}
            {directions && formValues?.time}
          </p>
          <p className='flex items-center text-sm text-ezBlack mb-2 sm:mb-0'>
            <span className='text-ezGreen block mr-1'>{speedSvg}</span>{' '}
            {directions && formValues?.speed}
          </p>
          <p className='flex items-center text-sm text-ezBlack mb-2 sm:mb-0'>
            <span className='text-ezGreen block mr-1'>{energySvg}</span>{' '}
            {directions && formValues?.energy}
          </p>
          <p className='flex items-center text-sm text-ezBlack mb-2 sm:mb-0'>
            <span className='text-ezGreen block mr-1'>{dollorSvg}</span>{' '}
            {directions && formValues?.cost}
          </p>
          <div className='w-max group relative'>
            <button className='focus:outline-none  font-medium flex items-center justify-center'>
              <p className='flex items-center text-sm text-ezBlack mb-2 sm:mb-0'>
                <span className='rounded-full block mr-1 w-5 h-5 border-2 border-ezGreen bg-ezGreen bg-opacity-50' />
                Range Halo
              </p>
            </button>
            <span className='absolute mb-1.5 w-72 bottom-full right-0 scale-0 transition-all rounded-lg bg-ezGreen p-2 text-sm text-white group-hover:scale-100'>
              Range Halo shows your suggested recharging stop on your trip based
              on your vehicle range. Select a charging station within the green
              Range Halo bubble.
            </span>
          </div>
          <div className='w-max group relative border-l border-ezLightGray hidden md:block pl-2.5'>
            <button className='text-white bg-ezGray mr-1 focus:outline-none font-medium flex items-center justify-center text-xs w-5 h-5 rounded-full'>
              <BsInfoLg />
            </button>
            <span className='absolute mb-1.5 w-72 bottom-full right-0 scale-0 transition-all rounded-lg bg-ezGreen p-2 text-sm text-white group-hover:scale-100'>
              These calculations are based on past user data. Factors such as
              weather, speed, traffic, and jurisdictional charges impact our
              estimates.
            </span>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-center border-t pt-4 border-ezLightGray'>
        <OutlineBtn
          handleEvent={() => {
            setSteps(1);
            setAllEVs(null);
          }}
          isDisabled={isLoading || tripLoading}
          text='Back'
        />
        <GrayBtn
          text='Save'
          btnType='button'
          isLoading={isLoading || tripLoading}
          isDisabled={isLoading || tripLoading}
          classNameCondition={!isLoading || !tripLoading}
          handleEvent={addTrip}
        />
      </div>
    </div>
  );
};

export default PlanTripSteps3;
