import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import Select from "react-select";
import { colourStyles } from "helper/helper";
import {
  evConnectorTypes,
  evNetworkTypes,
  fuelTypes,
  ownerTypes,
} from "helper/nrelData";
import { Filter } from "iconsax-react";
import RadioBtn from "components/btns/RadioBtn";
import { BsFilter } from "react-icons/bs";
import { initialFilters } from "helper/functionality";

export default function HomeFilter({
  formValues,
  setFormValues,
  applyFilters,
  resetFilter,
  currentTab,
  setCurrentTab,
}) {
  const [values, setValues] = useState(formValues);
  const [isOpen, setIsOpen] = useState(false);

  const handleFilters = () => {
    setFormValues(values);
    applyFilters(values);
  };

  const handleResetFilter = () => {
    setValues(initialFilters);
    resetFilter(initialFilters);
    handleFilters();
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className={
          currentTab === "sm"
            ? "text-sm md:text-base absolute top-24 left-2 z-10 md:hidden bg-ezGreen rounded-md text-white py-2 px-5 md:px-8 flex items-center"
            : "text-sm md:text-base bg-white hidden  rounded-md text-ezGray p-3 md:flex items-center hover:bg-ezLightGreen shadow-md"
        }
      >
        {currentTab === "sm" ? (
          <BsFilter className="w-5 h-5 mr-2" />
        ) : (
          <Filter className="w-6 h-6" />
        )}
        {currentTab === "sm" && "Filter"}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 -translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-4"
        beforeLeave={() => setIsOpen(false)}
      >
        <Menu.Items className="absolute z-30 left-8 md:right-1 md:-left-[18rem] lg:-left-[23.25rem] top-36 md:top-16 lg:w-64 w-64 overflow-y-auto md:max-h-[42rem] max-h-[28rem] md:overflow-visible md:max-w-2xl lg:min-w-[415px] md:min-w-[335px] origin-top rounded-lg bg-white shadow-lg  focus:outline-none md:z-20">
          <div className="headerFormSelect p-0 w-full">
            <div className="ez__PlanTripHead w-full md:pt-4 md:pb-2 px-4 md:px-5">
              <p className="text-ezGray text-xl font-semibold mb-2 hidden md:block">
                Filters
              </p>
              <hr className="w-full border border-gray-300 hidden md:block" />
            </div>

            <div className="ez__Body w-full flex flex-col gap-2 px-5 py-3">
              <div className="relative w-full text-sm text-ezGray">
                <label
                  htmlFor="fuel"
                  className="text-ezGray text-sm mb-1 block"
                >
                  Fuel Type
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Fuel"
                  isSearchable={true}
                  styles={colourStyles}
                  id="fuel"
                  name="fuel"
                  value={
                    values?.fuel && {
                      label: fuelTypes?.filter(
                        (fuel) => fuel?.value === values.fuel
                      )[0]?.title,
                      value: values.fuel,
                    }
                  }
                  onChange={(e) => setValues({ ...values, fuel: e?.value })}
                  options={fuelTypes?.map((fuel) => {
                    return {
                      label: fuel?.title,
                      value: fuel?.value,
                    };
                  })}
                />
              </div>
              <div className="relative w-full text-sm text-ezGray">
                <label
                  htmlFor="owner"
                  className="text-ezGray text-sm mb-1 block"
                >
                  Owner Type
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Owner"
                  isSearchable={true}
                  styles={colourStyles}
                  name="owner"
                  value={
                    values?.owner && {
                      label: ownerTypes?.filter(
                        (owner) => owner?.value === values.owner
                      )[0]?.title,
                      value: values.owner,
                    }
                  }
                  onChange={(e) => setValues({ ...values, owner: e?.value })}
                  options={ownerTypes?.map((owner) => {
                    return {
                      label: owner?.title,
                      value: owner?.value,
                    };
                  })}
                />
              </div>
              <div className="relative w-full text-sm text-ezGray">
                <label
                  htmlFor="network"
                  className="text-ezGray text-sm mb-1 block"
                >
                  Network Type
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select Network"
                  isSearchable={true}
                  styles={colourStyles}
                  name="network"
                  value={
                    values?.network && {
                      label: evNetworkTypes?.filter(
                        (network) => network?.value === values.network
                      )[0]?.title,
                      value: values?.network,
                    }
                  }
                  onChange={(e) => setValues({ ...values, network: e?.value })}
                  options={evNetworkTypes?.map((network) => {
                    return {
                      label: network?.title,
                      value: network?.value,
                    };
                  })}
                />
              </div>
              <div className="relative w-full text-sm text-ezGray">
                <label
                  htmlFor="connector"
                  className="text-ezGray text-sm mb-1 block"
                >
                  Connector Type
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select Connector"
                  isSearchable={true}
                  styles={colourStyles}
                  name="connector"
                  value={
                    values?.connector && {
                      label: evConnectorTypes?.filter(
                        (connector) => connector?.value === values.connector
                      )[0]?.title,
                      value: values?.connector,
                    }
                  }
                  onChange={(e) =>
                    setValues({
                      ...values,
                      connector: e?.value,
                    })
                  }
                  options={evConnectorTypes?.map((connector) => {
                    return {
                      label: connector?.title,
                      value: connector?.value,
                    };
                  })}
                />
              </div>
              <div className="relative w-full flex flex-wrap gap-1.5 my-1">
                <RadioBtn
                  multiple={true}
                  classNames="px-3 py-1 rounded-full text-sm"
                  text="Private Stations"
                  isSelected={values?.previousStation}
                  onClick={() =>
                    setValues({
                      ...values,
                      previousStation: !values?.previousStation,
                    })
                  }
                />
                <RadioBtn
                  multiple={true}
                  classNames="px-3 py-1 rounded-full text-sm"
                  text="AC Level 1"
                  isSelected={values?.ev_level1_evse_num}
                  onClick={() =>
                    setValues({
                      ...values,
                      ev_level1_evse_num: !values?.ev_level1_evse_num,
                    })
                  }
                />
                <RadioBtn
                  multiple={true}
                  classNames="px-3 py-1 rounded-full text-sm"
                  text="AC Level 2"
                  isSelected={values?.ev_level2_evse_num}
                  onClick={() =>
                    setValues({
                      ...values,
                      ev_level2_evse_num: !values?.ev_level2_evse_num,
                    })
                  }
                />
                <RadioBtn
                  multiple={true}
                  classNames="px-3 py-1 rounded-full text-sm"
                  text="DC Fast"
                  isSelected={values?.ev_dc_fast_num}
                  onClick={() =>
                    setValues({
                      ...values,
                      ev_dc_fast_num: !values?.ev_dc_fast_num,
                    })
                  }
                />
                <RadioBtn
                  multiple={true}
                  classNames="px-3 py-1 rounded-full text-sm"
                  text="Available Chargers"
                  isSelected={values?.status}
                  onClick={() =>
                    setValues({
                      ...values,
                      status: !values?.status,
                    })
                  }
                />
              </div>
              <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => handleResetFilter(values)}
                  className="mx-auto text-sm text-ezGreen bg-transparent hover:bg-transparent hover:text-ezGreen w-full py-2 border border-ezGreen rounded-md block"
                >
                  Reset
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(false);
                    handleFilters();
                  }}
                  className="mx-auto text-sm block text-white bg-ezGreen hover:bg-transparent hover:text-ezGreen w-full py-2 border border-ezGreen rounded-md"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
