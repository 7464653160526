import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spiner from 'helper/Spiner';
import { useSelector } from 'react-redux';
import useApiHook from 'hooks/useApiHook';

const VerifyAccount = () => {
  const { auth } = useSelector((state) => state);
  const { token } = useParams();
  const navigate = useNavigate();
  const { handleApiCall } = useApiHook();
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState({ error: false, message: '' });

  const handleVerification = async (token) => {
    setIsError({ error: false, message: '' });
    try {
      const result = await handleApiCall({
        method: 'get',
        url: `/verify/${token}`,
      });
      if (result?.status === 200) setIsVerification(true);
      if (result?.response?.status === 400) navigate('/home');
    } catch (error) {
      setIsError({ error: true, message: error?.response?.data?.error });
    }
  };

  useEffect(() => {
    token && handleVerification(token);
  }, [token]);

  return (
    <div className='w-full h-screen bg-ezMidWhite'>
      <div className='grid grid-cols-2 items-center w-full h-full	'>
        <div className='ez__AuthForm lg:mx-20 md:mx-10  xl:mx-48'>
          <div className='title w-full flex items-center justify-center flex-col'>
            <img
              src='/assets/images/logo.png'
              alt='Logo'
              className='h-20 w-auto block mb-8'
            />
            {isVerification ? (
              <div className=' w-full text-center rounded-md my-10 h-full relative'>
                <h3 className='text-ezBlack font-bold text-lg md:text-xl mb-6'>
                  Your email has been verified!
                </h3>
                <p className='text-base text-ezBlack text-center mb-5'>
                  <strong>Thanks for joining us!</strong> We'll occasionally
                  send you emails with helpful information, updates, and{' '}
                  exclusive offers. If you prefer not to receive these emails,
                  you can easily opt out by adjusting your{' '}
                  <strong>email notification settings</strong> after
                  registration.
                </p>
                <p className='mb-3 text-ezBlack text-base text-center'>
                  Please login and enjoy ezVOLTz.
                </p>
                <Link
                  to='/login'
                  className='text-white rounded-md bg-ezGreen border-ezGreen border hover:bg-transparent hover:text-ezGreen font-semibold block px-8 py-2'
                >
                  Log In
                </Link>
              </div>
            ) : isError?.error ? (
              <>
                <p className='text-ezBlack text-base text-center mb-10'>
                  {isError?.message}
                </p>
              </>
            ) : (
              <>
                <h3 className='text-ezBlack font-bold text-lg md:text-xl mb-6'>
                  Email is verifing
                </h3>
                <p className='text-ezBlack text-base text-center mb-10'>
                  We are verifing your email please wait.
                </p>
                <Spiner color='ezGreen' />
              </>
            )}
          </div>
        </div>
        <div className='h-screen w-full'>
          <img
            src='/assets/images/login.png'
            alt='Login'
            className='w-full h-full'
          />
        </div>
      </div>
    </div>
  );
};
export default VerifyAccount;
