import { dateAgo, tripStatus } from 'helper/functionality';
import { cross, inProgressSvg, tripTick } from 'helper/helper';
import moment from 'moment';
import { AiFillStop } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import {
  calenderSvg,
  lineSvg,
  tripCardClockSvg,
  tripLocatorPinSvg,
  tripStopSvg,
} from 'helper/helper';

const TripCard = ({ card }) => {
  const navigate = useNavigate();

  const openTripDetails = () => {
    navigate(
      `${
        card?.status === tripStatus?.inProgress
          ? `/inprogress-trip/detail/${card?._id}`
          : `/previous-trip/detail/${card?._id}`
      }`
    );
  };

  return (
    <div
      className={`ez__TripCard shadow-md md:max-w-[325px] hover:cursor-pointer flex flex-col bg-white rounded-lg overflow-hidden p-4 justify-between transition-all duration-300 ease-in-out border-${
        card?.status === tripStatus?.isCompleted
          ? 'ezGreen hover:bg-ezLightGreen'
          : card?.status === tripStatus?.inProgress
          ? 'ezOrange hover:bg-ezLightOrange'
          : card?.status === tripStatus?.isCancelled
          ? 'ezRed hover:bg-ezLightRed'
          : 'ezGray hover:bg-ezLightGray'
      }`}
      onClick={() => openTripDetails()}
    >
      <span
        className={`ez__PosBlock block absolute rotate-90 -bottom-4 -left-5 w-8 h-8 bg-${
          card?.status === tripStatus?.isCompleted
            ? 'ezGreen'
            : card?.status === tripStatus?.inProgress
            ? 'ezOrange'
            : card?.status === tripStatus?.isCancelled
            ? 'ezRed'
            : 'ezGray'
        }`}
      />
      <div
        className={`max-w-fit rounded-md mb-5 md:mb-0 p-2 flex items-center justify-center text-xs border text-${
          card?.status === tripStatus?.isCompleted
            ? 'ezGreen bg-ezLightGreen border-ezGreen'
            : card?.status === tripStatus?.inProgress
            ? 'ezOrange bg-ezLightOrange border-ezOrange'
            : card?.status === tripStatus?.isCancelled
            ? 'ezRed bg-ezLightRed border-ezRed'
            : 'ezGray bg-ezLightGray border-ezGray'
        }`}
      >
        {card?.status === tripStatus?.isCompleted ? (
          <span className='flex gap-x-1 text-ezGreen'>
            {tripTick} Completed
          </span>
        ) : card?.status === tripStatus?.inProgress ? (
          <span className='flex gap-x-1 text-ezOrange'>
            {inProgressSvg}
            In Progress
          </span>
        ) : card?.status === tripStatus?.isCancelled ? (
          <div className='cardIcon text-ezRed mb-5 md:mb-0'>
            {cross} Cancelled
          </div>
        ) : (
          <div className='flex gap-x-1'>
            <span className='border border-ezGray rounded-full w-max md:w-auto'>
              <AiFillStop className='w-4 h-4 text-ezGray' />
            </span>
            Not Started
          </div>
        )}
      </div>

      {/* <div className='ez__CardInfo flex w-full flex-wrap items-center justify-between'> */}
      <div className='text-center flex flex-col justify-center items-center'>
        <div className='flex flex-row justify-between text-sm my-4 text-ezGray w-full'>
          <div className='text-left'>
            <p className='text-black  text-xs'>FROM</p>
            <p>{card?.origin?.text?.slice(0, 10)}...</p>
          </div>
          <img
            src='/assets/svgs/originToDest.svg'
            alt='originToDest'
            className='w-[89px] h-auto mx-3'
          />
          <div className='text-left'>
            <p className='text-black text-xs'>TO</p>
            <p>{card?.destination?.text?.slice(0, 10)}...</p>
          </div>
        </div>
        <span>{lineSvg}</span>
        <div className='flex flex-row flex-wrap gap-y-2 gap-x-3 mt-3 md:mt-0 pt-4 items-center'>
          <p className='text-ezGray text-sm mr-2 flex align-middle items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{calenderSvg}</span>{' '}
            {card?.startDate
              ? moment(card?.startDate).format('MMMM DD, YYYY')
              : 'Not Scheduled'}
          </p>
          <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{tripCardClockSvg}</span>{' '}
            {new Date(card?.startTime).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </p>
          <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{tripStopSvg}</span>{' '}
            {card?.stops?.length}
          </p>
          <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            <span className='w-4 h-4 mr-2'>{tripLocatorPinSvg}</span>{' '}
            {card?.distance}
          </p>
          {/* <p className='text-ezGray text-sm mr-2 flex items-center mb-1'>
            {card?.startDate && dateAgo(card?.startDate)}
          </p> */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default TripCard;
