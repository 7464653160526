import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLogin } from 'redux/auth/authSlice';
import { AiOutlineUser } from 'react-icons/ai';
import { FaPencilAlt } from 'react-icons/fa';
import { checkUserAuthenticated } from 'helper/functionality';
import { profileSvg } from 'helper/helper';
import { toggleHomePage } from 'redux/dashbbaord/dashboardSlice';
import { BiLogOut } from 'react-icons/bi';

const UserDropDown = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserAuthenticated = checkUserAuthenticated(auth?.userInfo);

  const handleLogout = (route) => {
    dispatch(toggleLogin({ isLogin: false, userInfo: null }));
    dispatch(toggleHomePage(true));
    navigate(route);
  };

  function truncateText(text) {
    if (text.length > 18) return text.substring(0, 18) + '...';
    return text;
  }

  return (
    <Fragment>
      <Popover className='relative'>
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`justify-center items-center p-2 hover:cursor-pointer flex-grow flex h-full focus:outline-none ${
                auth?.userInfo?.user?.name?.length > 12
                  ? 'lg:min-w-[175px]'
                  : 'lg:min-w-[155px]'
              }
                ${
                  open
                    ? 'text-white bg-ezGreen hover:text-white'
                    : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                }`}
            >
              {auth?.userInfo?.user?.profileImage ? (
                <img
                  className={`h-6 w-6 sm:h-8 sm:w-8 rounded-full border block ${
                    open
                      ? 'text-white bg-ezGreen hover:text-white'
                      : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                  }`}
                  src={auth?.userInfo?.user?.profileImage}
                  alt='User Profile'
                />
              ) : (
                <span className='block'>
                  {profileSvg(open ? 'white' : 'black')}
                </span>

                // <div className='w-8 h-8 rounded-full overflow-hidden'>
                //   <img
                //     className={`h-6 w-6 sm:h-8 sm:w-8 rounded-full border block ${
                //       open
                //         ? 'text-white bg-ezGreen hover:text-white'
                //         : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                //     }`}
                //     src={'/assets/images/avatar.png'}
                //     alt='Guest Profile'
                //   />
                // </div>
              )}
              <span
                className={`ml-3 text-base hidden md:block ${
                  open ? 'text-white' : 'text-ezBlack'
                }`}
              >
                {auth?.userInfo?.user?.name?.slice(0, 12)}
                {auth?.userInfo?.user?.name?.length > 12 ? '...' : ''}
              </span>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute right-0 z-10 mt-2 mr-2 w-screen max-w-md transform px-2 sm:px-0'>
                <div className='absolute right-0 z-10 mt-2 w-11/12 md:w-full max-w-xs origin-top-right rounded-3xl bg-white shadow-lg focus:outline-none overflow-hidden '>
                  <div className='relative w-full block dropDownOverFlow py-1 pl-1'>
                    <div className='bg-ezLightWhite p-5 rounded-2xl m-4'>
                      <div
                        className={`ez__ProfilePicDiv flex flex-col md:flex-row ${
                          isUserAuthenticated ? 'items-start' : 'items-center'
                        }`}
                      >
                        <div className='block rounded-full w-20 h-20 relative z-1 group mb-3 md:mb-0'>
                          {auth?.userInfo?.user?.profileImage ? (
                            <img
                              src={auth?.userInfo?.user?.profileImage}
                              alt='Profile Avatar'
                              className='w-20 h-20 object-cover rounded-full'
                            />
                          ) : (
                            <div className='w-20 h-20 block'>
                              <img
                                className='rounded-full border border-ezGreen block'
                                src={'/assets/images/avatar.png'}
                                alt='Guest Profile'
                              />
                            </div>
                          )}
                          {isUserAuthenticated && (
                            <div className='ez__OverlayProfile absolute w-8 h-8 rounded-full bg-ezGreen bottom-0 right-0 cursor-pointer z-10 '>
                              <div className='relative w-8 h-8 flex items-center justify-center cursor-pointer'>
                                <Link
                                  to='/profile'
                                  onClick={close}
                                  className='absolute w-8 h-8 opacity-0 top-0 right-0 cursor-pointer'
                                />
                                <FaPencilAlt className='text-white w-3.5 h-3.5  cursor-pointer' />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='md:w-32 md:h-20 flex flex-col justify-center w-max md:ml-4'>
                          <p className='text-black flex text-sm font-bold justify-start mb-1'>
                            {auth?.userInfo?.user?.name &&
                              truncateText(auth?.userInfo?.user?.name)}
                          </p>
                          <p className='text-black md:flex text-xs justify-start mb-1 hidden'>
                            {auth?.userInfo?.user?.email &&
                              truncateText(auth?.userInfo?.user?.email)}
                          </p>
                          <p className='text-black flex text-sm justify-start mb-1 md:hidden'>
                            {auth?.userInfo?.user?.email}
                          </p>
                        </div>
                      </div>
                      {isUserAuthenticated ? (
                        <div className='flex flex-col md:pt-4 pt-1'>
                          {auth?.userInfo?.user?.phone && (
                            <>
                              <p className='text-black text-xs mb-0.5 font-bold'>
                                Phone
                              </p>
                              <p className='text-black flex text-sm items-center mb-1'>
                                {auth?.userInfo?.user?.phone}
                              </p>
                            </>
                          )}
                          {auth?.userInfo?.user?.city && (
                            <>
                              <p className='text-black text-xs mb-0.5 font-bold'>
                                Address
                              </p>
                              <p className='text-black flex text-sm items-center'>
                                {auth?.userInfo?.user?.city},{' '}
                                {auth?.userInfo?.user?.country},{' '}
                                {auth?.userInfo?.user?.state}
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className='mt-3 w-full grid sm:grid-cols-2 gap-3'>
                          <button
                            type='button'
                            onClick={() => handleLogout('/login')}
                            className='inline-flex w-full justify-center rounded-md border border-ezGreen TransAni bg-ezGreen hover:bg-transparent px-5 py-1 text-sm font-semibold text-white hover:text-ezGreen shadow-sm'
                          >
                            Login
                          </button>
                          <button
                            type='button'
                            onClick={() => handleLogout('/sign-up')}
                            className='inline-flex w-full justify-center rounded-md border border-ezGreen TransAni hover:bg-ezGreen px-5 py-1 text-sm font-semibold text-ezGreen hover:text-white shadow-sm'
                          >
                            Register
                          </button>
                        </div>
                      )}
                    </div>
                    {isUserAuthenticated && (
                      <div className='px-4'>
                        <button
                          onClick={() => {
                            close();
                            navigate('/profile');
                          }}
                          className='w-full flex items-center text-left  hover:bg-ezLightGreen pr-8 py-2 border-b border-b-ezLightGray text-sm text-black hover:text-black'
                        >
                          <AiOutlineUser className='w-5 h-5 mr-2' /> View
                          Profile
                        </button>
                        <button
                          onClick={() => handleLogout('/login')}
                          className='w-full flex items-center text-left  hover:bg-ezLightRed pr-8 pt-3 pb-2 border-b border-b-ezLightGray text-sm text-ezRed hover:text-ezRed'
                        >
                          <BiLogOut className='w-5 h-5 mr-2 text-ezRed' /> Sign
                          out
                        </button>
                      </div>
                    )}
                    <div className='flex md:items-start md:flex-row flex-col items-center justify-center w-full pb-2 mt-2'>
                      <Link
                        to='/privacy-policy'
                        className='block w-2/3 md:text-right text-center px-2 md:py-2 pb-2 text-black hover:text-black text-xs hover:bg-ezLightGreen rounded-md ml-0 md:ml-8'
                      >
                        Privacy Policy
                      </Link>
                      <span className='md:block text-black font-bold mx-1 hidden'>
                        .
                      </span>
                      <Link
                        to='/terms-and-conditions'
                        className='block md:text-left text-center pl-2 md:py-2 text-black hover:text-black text-xs hover:bg-ezLightGreen rounded-md w-full mr-0 md:mr-8'
                      >
                        Terms & Conditions
                      </Link>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Fragment>
  );
};

export default UserDropDown;
