import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  handlPlanTripModal,
  toggleIsPlanApiCall,
} from "redux/dashbbaord/dashboardSlice";
import PlanTripSteps1And2 from "components/planTrip/PlanTripSteps1And2";
import PlanTripSteps3 from "components/planTrip/PlanTripSteps3";
import { MdCancel } from "react-icons/md";
import { useJsApiLoader } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { initialValues } from "helper/functionality";
import useApiHook from "hooks/useApiHook";
import Loader from "helper/Loader";
import SuccessModal from "modals/common/SuccessModal";

export default function PlanTrip() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiCall, isApiLoading } = useApiHook();
  const { auth, dashboard } = useSelector((state) => state);
  const cancelButtonRef = useRef(null);
  const [steps, setSteps] = useState(1);
  const [stepCompleted, setStepCompleted] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [tripDirections, setTripDirections] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });
  const [directions, setDirections] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addTrip = async () => {
    let startTime = null;
    if (formValues?.startDate && formValues.startTime) {
      let setStartTime = new Date(formValues.startTime);
      setStartTime.setMonth(new Date(formValues.startDate)?.getMonth());
      setStartTime.setFullYear(new Date(formValues.startDate)?.getFullYear());
      setStartTime.setDate(new Date(formValues.startDate)?.getDate());
      startTime = new Date(setStartTime);
    }
    let data = {
      origin: {
        text: formValues.startFrom,
        latitude: tripDirections?.routes[0]?.legs[0]?.start_location?.lat(),
        longitude: tripDirections?.routes[0]?.legs[0]?.start_location?.lng(),
      },
      destination: {
        text: formValues.destination,
        latitude:
          tripDirections?.routes[0]?.legs[
            tripDirections?.routes[0]?.legs?.length - 1
          ]?.end_location?.lat(),
        longitude:
          tripDirections?.routes[0]?.legs[
            tripDirections?.routes[0]?.legs?.length - 1
          ]?.end_location?.lng(),
      },
      stops: formValues.inBetween?.map((stop) => ({
        name: stop?.value,
        type: stop?.type,
      })),
      startDate: formValues.startDate
        ? formValues.startDate >= new Date()
          ? new Date(formValues.startDate)
          : new Date()
        : null,
      startTime: startTime
        ? startTime >= new Date()
          ? startTime
          : new Date()
        : null,
      chargersType: formValues.chargersType,
      connector: formValues.connectorType,
      network: formValues.network,
      distance: formValues.distance,
      time: formValues.time,
      speed: formValues.speed,
      energy: formValues.energy,
      cost: formValues.cost,
      vehicleId: formValues.vehicle?._id,
      avoidTolls: formValues.avoidTolls,
      avoidTraffic: formValues.avoidTraffic,
      avoidHighways: formValues.avoidHighways,
      hotels: formValues.hotels,
      restaurants: formValues.restaurants,
      campGround: formValues.campGround,
    };

    const result = await handleApiCall({
      method: "post",
      url: `/trip/${auth?.userInfo?.user?._id}`,
      data,
    });

    if (result?.status === 201) {
      setFormValues(initialValues);
      setDirections(null);
      setTripDirections(null);
      setStepCompleted(true);
      setIsModalOpen(true);
    }
  };

  const handlDone = () => {
    dispatch(handlPlanTripModal(false));
    dispatch(toggleIsPlanApiCall(true));
    setSteps(1);
    setStepCompleted(false);
    navigate("/my-trips");
  };

  if (!isLoaded) return <Loader />;

  return (
    <Transition.Root show={dashboard?.isPlanModal || false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 flex items-center justify-center"
        initialFocus={cancelButtonRef}
        onClose={() => dispatch(handlPlanTripModal(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="relative z-10 max-w-4xl w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform text-left bg-tansparent transition-all sm:my-8 sm:max-w-4xl w-full">
              {!stepCompleted && (
                <div className="px-8 pt-8 pb-6 bg-white shadow-xl w-full h-full rounded-xl">
                  <div className="ez__PlanTripHead w-full flex items-center justify-between">
                    <div className="flex flex-col">
                      <div className="text-lg font-bold my-1">Plan a Trip</div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(handlPlanTripModal(false));
                        setStepCompleted(false);
                        setFormValues(initialValues);
                        setSteps(1);
                      }}
                      className="bg-white text-ezDarkGray rounded-full block w-max mb-auto"
                    >
                      <MdCancel className="w-8 h-8" />
                    </button>
                  </div>
                  <hr className="border-gray-300 my-3" />
                  {+steps === 1 ? (
                    <PlanTripSteps1And2
                      userInfo={auth?.userInfo}
                      setSteps={setSteps}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      directions={directions}
                      setDirections={setDirections}
                      setTripDirections={setTripDirections}
                    />
                  ) : +steps === 2 ? (
                    <PlanTripSteps3
                      setSteps={setSteps}
                      formValues={formValues}
                      directions={directions}
                      setFormValues={setFormValues}
                      tripDirections={tripDirections}
                      tripLoading={isApiLoading}
                      addTrip={addTrip}
                    />
                  ) : null}
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
          {stepCompleted && (
            <SuccessModal
              isModal={isModalOpen}
              handleCloseModal={handlDone}
              title={"Trip Confirmation"}
              description={"Congratulations! Your trip has been saved."}
            />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
