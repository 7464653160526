export const getBrowserInfo = async () => {
  const userAgent = navigator.userAgent;
  let browserName;

  // Check for browser name
  if (userAgent.includes('Chrome')) {
    browserName = 'Google Chrome';
  } else if (userAgent.includes('Firefox')) {
    browserName = 'Mozilla Firefox';
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    browserName = 'Apple Safari';
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera';
  } else if (userAgent.includes('Edge')) {
    browserName = 'Microsoft Edge';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    browserName = 'Internet Explorer';
  } else {
    browserName = 'Unknown';
  }

  return browserName;
};
