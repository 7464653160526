import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { toggleClearPages } from "redux/dashbbaord/dashboardSlice";
import { checkUserAuthenticated } from "helper/functionality";
import { FaRegUser } from "react-icons/fa";
import { MdMailOutline, MdOutlinePayment } from "react-icons/md";
import { chargingSvg } from "helper/helper";
import {
  BatteryCharging,
  Happyemoji,
  InfoCircle,
  Lock,
  Note,
  Profile2User,
  Sms,
  Wallet2,
} from "iconsax-react";
import { CiMail } from "react-icons/ci";

const InfoDropDown = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const isUserAuthenticated = checkUserAuthenticated(auth?.userInfo);

  return (
    <Fragment>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`text-sm flex items-center justify-center py-4 px-5 hover:cursor-pointer lg:min-w-[80px] h-full focus:outline-none ${
                open
                  ? "text-white bg-ezGreen hover:text-white"
                  : "text-ezBlack hover:bg-ezLightGreen bg-transparent"
              }`}
            >
              <span className="sr-only">Open main menu</span>
              <GiHamburgerMenu className="block h-6 w-6" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-20 mr-2 mt-1 w-screen max-w-xs transform px-2 sm:px-0">
                <ul className="bg-white rounded-xl m-4 border-ezGray shadow-lg">
                  <li>
                    <NavLink
                      to="/"
                      className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent hover:rounded-t-xl rounded-t-md border-b border-b-ezLightGray"
                    >
                      <Profile2User className="w-5 h-5 mr-3" /> About Our App
                    </NavLink>
                  </li>
                  {isUserAuthenticated && (
                    <li>
                      <NavLink
                        to="/charging-activity"
                        className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent hover:rounded-t-xl rounded-t-md border-b"
                        onClick={() => {
                          dispatch(toggleClearPages());
                          close();
                        }}
                      >
                        <BatteryCharging className="w-5 h-5 mr-3" />
                        Charging History
                      </NavLink>
                    </li>
                  )}
                  {isUserAuthenticated && (
                    <li>
                      <NavLink
                        to="/my-payment-methods"
                        className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent hover:rounded-t-xl rounded-t-md border-b"
                        onClick={() => {
                          dispatch(toggleClearPages());
                          close();
                        }}
                      >
                        <Wallet2 className="w-5 h-5 mr-3" />
                        Payment Methods
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      to="/terms-and-conditions"
                      className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent border-b border-b-ezLightGray"
                    >
                      <Note className="w-5 h-5 mr-3" />
                      Terms & Conditions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/privacy-policy"
                      className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent border-b border-b-ezLightGray"
                    >
                      <Lock className="w-5 h-5 mr-3" />
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/feedback"
                      className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent border-b border-b-ezLightGray"
                      onClick={() => {
                        dispatch(toggleClearPages());
                        close();
                      }}
                    >
                      <Happyemoji className="w-5 h-5 mr-3" />
                      Feedback
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact-us"
                      className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent border-b border-b-ezLightGray"
                      onClick={() => {
                        dispatch(toggleClearPages());
                        close();
                      }}
                    >
                      <Sms className="w-5 h-5 mr-3" />
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="http://www.ezvoltz.com/about-us/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm flex items-center py-4 px-5 text-gray-700 hover:bg-ezLightGreen hover:text-gray-700 bg-transparent hover:rounded-b-xl rounded-b-md"
                    >
                      <InfoCircle className="w-5 h-5 mr-3" />
                      About us
                    </a>
                  </li>
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Fragment>
  );
};

export default InfoDropDown;
