import { Disclosure } from '@headlessui/react';
import { MdOutlineCancel } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import UserDropDown from './UserDropDown';
import { useDispatch, useSelector } from 'react-redux';
import {
  handlPlanTripModal,
  handlSidebar,
  toggleHomePage,
  toggleTripsPage,
  toggleVehiclesPage,
} from 'redux/dashbbaord/dashboardSlice';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { checkUserAuthenticated } from 'helper/functionality';
import { homeSvg, locationSvg, searchVehicle, tripCarSvg } from 'helper/helper';
import InfoDropDown from './InfoDropDown';
import NotificationDropDown from './NotificationDropDown';

function Header({ setIsPlanTripModal, setIsNoTripModal, setIsNoVehicleModal }) {
  const { dashboard, auth } = useSelector((state) => state);
  const isUserAuthenticated = checkUserAuthenticated(auth?.userInfo);
  const dispatch = useDispatch();

  return (
    <Disclosure as='nav' className='bg-white ez__Header z-10 shadow-sm'>
      {({ open }) => (
        <>
          <div className='flex flex-1 items-center justify-between lg:hidden'>
            <div className='flex items-center lg:hidden'>
              <Disclosure.Button
                onClick={() => dispatch(handlSidebar(!dashboard?.isSidebar))}
                className='inline-flex items-center justify-center rounded-md p-2 text-base text-ezGray focus:outline-none'
              >
                <span className='sr-only'>Open main menu</span>
                {dashboard?.isSidebar ? (
                  <MdOutlineCancel className='block h-6 w-6' />
                ) : (
                  <GiHamburgerMenu className='block h-6 w-6' />
                )}
              </Disclosure.Button>
            </div>
            <a href='https://www.ezvoltz.com' className='items-center'>
              <img
                className='block h-6 sm:h-8 w-auto'
                src='/assets/images/ezvoltzLogo.png'
                alt='Your Company'
              />
            </a>

            <UserDropDown />
          </div>
          <div className='hidden lg:flex flex-row justify-between h-16'>
            <div className='h-auto m-auto flex flex-shrink-0 justify-left px-11'>
              <a href='https://www.ezvoltz.com' className='items-center'>
                <img
                  className='block h-6 sm:h-8 w-auto'
                  src='/assets/images/ezvoltzLogo.png'
                  alt='Your Company'
                />
              </a>
            </div>
            <div className='flex flex-row h-full w-full justify-center'>
              {isUserAuthenticated ? (
                <>
                  <NavLink
                    to='/home'
                    onClick={() => dispatch(toggleHomePage(true))}
                    className={`text-sm flex items-center justify-center py-4 px-2 lg:min-w-[140px] ${
                      dashboard?.isHomePage
                        ? 'text-white bg-ezGreen hover:text-white'
                        : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                    }`}
                  >
                    <span className='flex justify-center mr-2'>
                      {homeSvg(dashboard?.isHomePage ? 'white' : 'black')}
                    </span>
                    Home
                  </NavLink>
                  <button
                    type='button'
                    onClick={() =>
                      isUserAuthenticated
                        ? dispatch(handlPlanTripModal(true))
                        : setIsPlanTripModal(true)
                    }
                    className={`text-sm flex items-center justify-center py-4 px-5 lg:min-w-[140px]  text-ezBlack hover:bg-ezLightGreen bg-transparent`}
                  >
                    <span className='flex justify-center mr-2'>
                      {tripCarSvg('black')}
                    </span>
                    Plan a Trip
                  </button>
                  <NavLink
                    to='/my-trips'
                    onClick={() => dispatch(toggleTripsPage(true))}
                    className={`text-sm flex items-center justify-center py-4 px-5 lg:min-w-[140px] ${
                      dashboard?.isTripsPage
                        ? 'text-white bg-ezGreen hover:text-white'
                        : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                    }`}
                  >
                    <span className='flex justify-center mr-2'>
                      {locationSvg(dashboard?.isTripsPage ? 'white' : 'black')}
                    </span>
                    My Trips
                  </NavLink>
                  <NavLink
                    to='/my-vehicles'
                    onClick={() => dispatch(toggleVehiclesPage(true))}
                    className={`text-sm flex items-center justify-center py-4 px-5 lg:min-w-[140px] ${
                      dashboard?.isVehiclesPage
                        ? 'text-white bg-ezGreen hover:text-white'
                        : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                    }`}
                  >
                    <span className='flex justify-center mr-2'>
                      {searchVehicle(
                        dashboard?.isVehiclesPage ? 'white' : 'black'
                      )}
                    </span>
                    My Vehicles
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to='/home'
                    onClick={() => dispatch(toggleHomePage(true))}
                    className={`text-sm flex items-center justify-center py-4 px-2 lg:min-w-[140px] ${
                      dashboard?.isHomePage
                        ? 'text-white bg-ezGreen'
                        : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                    }`}
                  >
                    <span className='flex justify-center mr-2'>
                      {homeSvg(dashboard?.isHomePage ? 'white' : 'black')}
                    </span>
                    Home
                  </NavLink>
                  <button
                    type='button'
                    onClick={() =>
                      isUserAuthenticated
                        ? dispatch(handlPlanTripModal(true))
                        : setIsPlanTripModal(true)
                    }
                    className={`text-sm flex items-center justify-center py-4 px-2 lg:min-w-[140px] ${
                      dashboard?.isPlanModal
                        ? 'text-white bg-ezGreen'
                        : 'text-ezBlack hover:bg-ezLightGreen bg-transparent'
                    }`}
                  >
                    <span className='flex justify-center mr-2'>
                      {tripCarSvg(dashboard?.isPlanModal ? 'white' : 'black')}
                    </span>{' '}
                    Plan a Trip
                  </button>
                  <button
                    type='button'
                    onClick={() => setIsNoTripModal(true)}
                    className='text-sm flex items-center justify-center py-4 px-2 lg:min-w-[140px] text-ezBlack hover:bg-ezLightGreen bg-transparent'
                  >
                    <span className='flex justify-center mr-2'>
                      {locationSvg('black')}
                    </span>{' '}
                    My Trips
                  </button>
                  <button
                    type='button'
                    onClick={() => setIsNoVehicleModal(true)}
                    className='text-sm flex items-center justify-center py-4 px-2 lg:min-w-[140px] text-ezBlack hover:bg-ezLightGreen bg-transparent'
                  >
                    <span className='flex justify-center mr-2'>
                      {searchVehicle('black')}
                    </span>{' '}
                    My Vehicles
                  </button>
                </>
              )}
            </div>
            <div className='flex flex-row justify-end h-full'>
              {isUserAuthenticated && <NotificationDropDown />}
              <UserDropDown />
              <InfoDropDown />
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}

export default Header;
