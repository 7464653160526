import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Spiner from "helper/Spiner";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useApiHook from "hooks/useApiHook";
import { toast } from "react-toastify";
import PrimaryBtn from "components/btns/PrimaryBtn";
import { FaArrowLeft } from "react-icons/fa";
import AuthModel from "modals/common/authModel";

const ForgotPassword = () => {
  const { auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isVerification, setIsVerification] = useState(false);
  const [userEmail, setUserEmail] = useState(false);

  const handleResetPassword = async (values) => {
    const result = await handleApiCall({
      method: "post",
      url: "/forgot-password",
      data: values,
    });
    if (result.status === 200) {
      toast.success("Reset password email sent successfully.");
      setIsVerification(true);
      setUserEmail(values.email);
    }
  };

  useEffect(() => {
    if (auth?.isLogin) navigate("/home");
  }, [auth?.isLogin]);

  return (
    <div className="w-full h-screen bg-ezMidWhite">
      <div className="flex flex-col   lg:grid  lg:grid-cols-2 px-4 lg:px-0 items-center w-full h-full">
        <div className="h-auto w-full items-center py-10 flex flex-col justify-center lg:hidden">
          <img
            className="h-10 sm:h-14 w-auto"
            src="/assets/images/ezvoltzLogo.png"
            alt="Your Company"
          />
        </div>
        <div className="ez__AuthForm lg:px-4 md:px-10  xl:mx-32">
          <div className="title w-full flex justify-center flex-col">
            <div className="flex gap-3">
              <button
                className="mb-6 flex items-center gap-4"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <FaArrowLeft className="w-5 h-5" color="#667085" />
              </button>
              <span className="text-sm text-ezNuturalGray">
                Back to sign in
              </span>
            </div>
            <h3 className="text-2xl font-semibold mb-4 text-ezBlack">
              Reset your password
            </h3>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Please enter valid email")
                  .required("Email is required"),
              })}
              onSubmit={handleResetPassword}
            >
              {({ resetForm }) => (
                <>
                  <Form className="ez__Form w-full">
                    <span className="text-sm text-ezNuturalGray">EMAIL</span>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="w-full block border border-ezMidGray rounded-lg  text-base text-ezBlack p-2 mb-4"
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => (
                        <p className="text-sm text-ezRed block mb-4 -mt-3">
                          {msg}
                        </p>
                      )}
                    />
                    <PrimaryBtn
                      btnType="submit"
                      text="Reset Password"
                      isApiLoading={isApiLoading}
                    />
                  </Form>
                  <AuthModel
                    isModal={isVerification}
                    handleCloseModal={() => {
                      setIsVerification(false);
                      resetForm();
                    }}
                    title="Reset Password"
                    descriptionBefore="ezVOLTz sent you a forgot password email. Please check your inbox and follow the instructions to reset your password."
                    handleResendEmail={() =>
                      handleResetPassword({ email: userEmail })
                    }
                    resendTextBefore="If email is not received"
                  />
                </>
              )}
            </Formik>
          </div>
        </div>
        <div className="h-screen w-full hidden lg:block">
          <img
            src="/assets/images/login.png"
            alt="Login"
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
